import { AppContentListItem } from "JS/React/Components/AppContentListItem";
import { CSSProperties } from "react";
import {
    Content,
    Course,
    Gifts,
    ReceivedCourse,
    VideoContent,
} from "JS/Models";

export interface SpeakerPlaylistRowItemProps {
    index: number;
    style: CSSProperties;
    listItem: VideoContent | Gifts | Content | Course | ReceivedCourse;
    imageUrl: string;
    handleToogleCheckBox?: (nid: string, value: boolean) => void;
    isCheckedItem: boolean;
}

export const SpeakerPlaylistRowItem = (props: SpeakerPlaylistRowItemProps) => {
    const {
        index,
        style,
        listItem,
        isCheckedItem,
        handleToogleCheckBox,
        imageUrl,
    } = props;

    return (
        <AppContentListItem
            style={style}
            key={`${listItem.nid}-${index}`}
            nid={listItem.nid}
            skuId={listItem.sku_id}
            description={listItem.description}
            imageUrl={imageUrl}
            title={listItem.title}
            acquiredDate={listItem.acquired_date}
            releaseDate={listItem.release_date}
            handleToogleCheckBox={handleToogleCheckBox}
            isCheckedItem={isCheckedItem}
            isFromSpeakerPlaylist={true}
        />
    );
};
