import React, { useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { AppSearchBar } from "./AppSearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppFiltersDialog } from "./AppFiltersDialog";
import { ContentSpeakerName } from "JS/Redux/Attributes";
import AppMultiTagSelect, { MultiAutocompleteProps } from "./AppMultiTagSelect";
import { AppButton } from "./AppButton";

export interface AppHeaderProps extends InternalStandardProps<GridProps> {
    title: string;
    searchPlaceholder?: string;
    searchQuery?: string;
    canGoBack?: boolean;
    searchable?: boolean;
    showTabs?: boolean;
    tabs?: any;
    selectedTab?: string;
    setSelectedTab?: (val: string) => void;
    setSearchQuery?: (val: string) => void;
    onBackClick?: () => void;
    onSearchFieldClick?: () => void;
    filterable?: boolean;
    uniqueSpeakers?: ContentSpeakerName[];
    onToggleSpeakerSelect?: MultiAutocompleteProps["onChange"];
    selectedSpeakers?: string[];
    handleClearFilters?: () => void;
    resetPagination?: () => void;
}

export const AppHeader = (props: AppHeaderProps) => {
    const classes = useStyles(props);

    const {
        className,
        searchQuery,
        searchPlaceholder,
        title,
        canGoBack,
        searchable = true,
        filterable = false,
        tabs,
        showTabs,
        selectedTab,
        setSearchQuery,
        onBackClick = () => {},
        setSelectedTab,
        onSearchFieldClick = () => {},
        uniqueSpeakers,
        onToggleSpeakerSelect,
        selectedSpeakers,
        handleClearFilters,
        resetPagination,
        ...rest
    } = props;

    const { filterDialogOpen, toogleFilterDialog } = useHeaderHandlers();

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            toogleFilterDialog();
        }
    };

    return (
        <Grid {...rest} container alignItems={"center"}>
            <Grid item md={6} xs={12}>
                <div
                    className={clsx(classes.titleContainer, classes.item, {
                        [classes.back]: canGoBack === true,
                    })}
                    onClick={onBackClick}
                >
                    {canGoBack && (
                        <FontAwesomeIcon
                            className={classes.backIcon}
                            icon={["fas", "chevron-left"]}
                        />
                    )}
                    <Typography
                        className={clsx(classes.headingItem)}
                        fontWeight={"bold"}
                        variant="h5"
                    >
                        {title}
                    </Typography>
                </div>
            </Grid>
            {showTabs && (
                <Box
                    marginTop={"22px"}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-around"}
                >
                    {Object.values(tabs).map((tab: string) => (
                        <span
                            key={tab}
                            onClick={() => setSelectedTab(tab)}
                            className={clsx(
                                classes.tab,
                                selectedTab === tab && classes.selectedTab,
                            )}
                        >
                            {tab}
                        </span>
                    ))}
                </Box>
            )}
            {searchable && (
                <>
                    <Grid
                        item
                        className={classes.searchItem}
                        md={filterable ? 5 : 6}
                        xs={filterable ? 11 : 12}
                    >
                        <AppSearchBar
                            className={clsx(
                                !searchable && classes.hideVisibility,
                            )}
                            value={searchQuery}
                            onChange={(e) => {
                                if (setSearchQuery) {
                                    setSearchQuery(e.target.value);
                                }
                            }}
                            placeholder={searchPlaceholder}
                            onCancel={() => {
                                if (setSearchQuery) {
                                    setSearchQuery("");
                                }
                            }}
                            onClick={onSearchFieldClick}
                        />
                    </Grid>
                    {filterable && (
                        <>
                            <Grid
                                item
                                className={classes.searchItem}
                                md={1}
                                xs={1}
                            >
                                {selectedSpeakers?.length > 0 && (
                                    <Box className={classes.filterCounter}>
                                        {selectedSpeakers?.length >= 100
                                            ? "99+"
                                            : selectedSpeakers?.length}
                                    </Box>
                                )}
                                <FontAwesomeIcon
                                    className={clsx(
                                        classes.filterIcon,
                                        selectedSpeakers?.length > 0 &&
                                            classes.selectedFilter,
                                    )}
                                    icon={["fas", "filter"]}
                                    onClick={() => {
                                        onSearchFieldClick &&
                                            onSearchFieldClick();
                                        toogleFilterDialog();
                                        resetPagination && resetPagination();
                                    }}
                                />
                            </Grid>
                            <AppFiltersDialog
                                showTitle
                                open={filterDialogOpen}
                                onClose={toogleFilterDialog}
                                titleText="Add Filters"
                                selectedSpeakers={selectedSpeakers}
                            >
                                <Grid container>
                                    <Grid item xs={12}>
                                        <AppMultiTagSelect
                                            handleChange={onToggleSpeakerSelect}
                                            optionList={uniqueSpeakers}
                                            optionKey="speakerName"
                                            value={selectedSpeakers}
                                            label="Search Speaker"
                                            placeholder="Search Speaker"
                                            handleKeyPress={handleKeyPress}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.btnClearWrapper}
                                    >
                                        {handleClearFilters && (
                                            <AppButton
                                                onClick={() => {
                                                    handleClearFilters();
                                                }}
                                                style={{ marginRight: "20px" }}
                                                buttonVariant="primary-filled"
                                                className={
                                                    classes.speakerFilterBtn
                                                }
                                            >
                                                Clear Filters
                                            </AppButton>
                                        )}

                                        <AppButton
                                            onClick={() => {
                                                toogleFilterDialog();
                                            }}
                                            buttonVariant="primary-filled"
                                            className={classes.speakerFilterBtn}
                                        >
                                            Done
                                        </AppButton>
                                    </Grid>
                                </Grid>
                            </AppFiltersDialog>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};

const useHeaderHandlers = () => {
    const [filterDialogOpen, setFilterDialogOpen] = useState<boolean>(false);

    const toogleFilterDialog = () => {
        setFilterDialogOpen(!filterDialogOpen);
    };

    return {
        filterDialogOpen,
        toogleFilterDialog,
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        headingItem: {
            color: theme.palette.grey.A100,
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        item: {
            padding: theme.spacing(2),
        },
        searchItem: {
            padding: theme.spacing(2),
            [theme.breakpoints.down("md")]: {
                padding: theme.spacing(2, 0),
            },
        },
        titleContainer: {
            display: "flex",
            alignItems: "center",
        },
        backIcon: {
            color: theme.palette.grey.A100,
            fontSize: "20px",
            marginRight: theme.spacing(2),
        },
        back: {
            cursor: "pointer",
            transition: "0.2s ease-in",
        },
        hideVisibility: {
            visibility: "hidden",
        },
        tab: {
            cursor: "pointer",
            fontSize: "1rem",
            color: theme.palette.grey[800],
            fontWeight: 500,
            border: `2px solid ${theme.palette.grey[800]}`,
            padding: "4px 28px",
            borderRadius: "25px",
        },
        selectedTab: {
            color: theme.colors.white,
            backgroundColor: theme.palette.grey[900],
            border: `2px solid ${theme.palette.grey[900]}`,
        },
        filterIcon: {
            fontSize: "26px",
            cursor: "pointer",
            color: theme.palette.grey[600],
        },
        selectedFilter: {
            color: `${theme.palette.primary.main} !important`,
        },
        menuItem: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.common.black,
            "& .Mui-selected": {
                color: `${theme.palette.common.black} !important`,
                backgroundColor: `${theme.palette.grey[900]} !important`,
            },
        },
        filterCounter: {
            position: "absolute",
            minHeight: "22px",
            minWidth: "22px",
            height: "auto",
            width: "auto",
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "-16px 0px 10px 15px",
            fontSize: "12px",
            fontWeight: "500",
        },
        btnClearWrapper: {
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: "16px 0 16px 16px",
        },
        speakerFilterBtn: {
            "&:focus": {
                backgroundColor: theme.palette.primary.main,
            },
            "&:active": {
                backgroundColor: theme.palette.primary.main,
            },
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
            },
        },
    }),
);
