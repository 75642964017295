import React, { useCallback, ReactElement, useRef } from "react";
import clsx from "clsx";
import {
    BottomNavigation,
    BottomNavigationAction,
    InternalStandardProps,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";
import { useRouting } from "../Hooks/Routes";
import { matchPath, useHistory } from "react-router-dom";
import { AppButton } from "../Components/AppButton";
import { PaymentMethod } from "../Components/ContentPurchase/PaymentMethodRadio";
import { PaginationInfo } from "JS/Types/Pagination";
import { messaging } from "JS/Helpers/UserMessaging";
import { useGlobalNavStack } from "../Hooks/NavStack";
import { useAppSelector } from "JS/Redux/Store";
import { AppCompletionProgress } from "JS/React/Components/AppCompletionProgress";
import { RecommendationsManager } from "../Container/RecommendationsManager";
import { usePermissionIfDenied } from "../Hooks/Notification";
import { ForegroundTasks } from "../Components/ForegroundTasks";

export enum NavigationTypes {
    HOME = "HOME",
    AUDIOS = "AUDIOS",
    VIDEOS = "VIDEOS",
    COURSES = "COURSES",
    MORE = "MORE",
}

export const Layout = (props: LayoutProps) => {
    const classes = useStyles(props);
    const {
        setGlobalHomeStack,
        setGlobalAudiosStack,
        setGlobalVideosStack,
        setGlobalCoursesStack,
        setGlobalMoreStack,
        homeStack,
        audiosStack,
        videosStack,
        coursesStack,
        moreStack,
    } = useGlobalNavStack();
    const {
        className,
        children,
        purchaseNavText,
        isPurchasing,
        method,
        activeGiftItem,
        purchaseEnabled,
        dialogs,
        disableLayout,
        navId,
        navPath,
        isBuyDisabled,
        onBuyClick,
        onLastContentHit = () => {},
        availableCredits,
        paginationInfo,
        isCaptureScroll,
        ...rest
    } = props;

    const { linkProvider } = useRouting();
    const history = useHistory();

    const downloadState = useAppSelector((state) => state.download);
    const {
        showDownloadProgress,
        downloadContentName,
        downloadContentPercentage,
    } = downloadState;

    const { onGettingPermission } = usePermissionIfDenied();

    const observer = useRef<IntersectionObserver>();

    const lastElementRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    onLastContentHit();
                }
            });
            if (node) observer.current.observe(node);
        },
        [onLastContentHit],
    );

    const moreTabExtraRoutes: string[] = [
        linkProvider.react.events().index(),
        linkProvider.react.events().detail(),
        linkProvider.react.webcast().index(),
        linkProvider.react.appInfo().index(),
        linkProvider.react.playlist().index(),
        linkProvider.react.feedback().index(),
        linkProvider.react.favorites().index(),
        linkProvider.react.favorites().favoriteAudiosListing(),
        linkProvider.react.favorites().favoriteVideosDetail(),
        linkProvider.react.documents().index(),
        linkProvider.react.documents().detail(),
        linkProvider.react.gifts().index(),
        linkProvider.react.gifts().listing(),
        linkProvider.react.gifts().detail(),
        linkProvider.react.gifts().giftsGivenListing("gifts-given"),
        linkProvider.react.gifts().giftsGivenContentListing(),
        linkProvider.react.gifts().giftAudioDetail(),
        linkProvider.react.gifts().giftAudioDetail(),
        linkProvider.react.gifts().sync(),
        linkProvider.react.downloadingQueue().index(),
        linkProvider.react.appSettings().index(),
        linkProvider.react.giftDashboard().index(),
        linkProvider.react.gifts().courseDetail(),
        linkProvider.react.gifts().courseMediaStepDetail(),
        linkProvider.react.fycStatus().index(null),
    ];

    const getNavItems = (): NavItem[] => {
        let items: NavItem[] = [];
        items = [
            {
                id: NavigationTypes.HOME,
                value: NavigationTypes.HOME,
                title: "Home",
                icon: <FontAwesomeIcon icon={["fas", "house"]} />,
                route: linkProvider.react.home(),
                lastVisited: homeStack?.lastVisited
                    ? homeStack?.lastVisited
                    : "",
                extraRoutes: [
                    linkProvider.react.mixedContent().index(),
                    linkProvider.react.mixedContent().audioDetail(),
                    linkProvider.react.mixedContent().videoDetail(),
                    linkProvider.react.mixedContent().bundleDetail(),
                    linkProvider.react.mixedContent().bundlePlay(),
                    linkProvider.react.mixedContent().courseDetail(),
                    linkProvider.react.mixedContent().courseMediaStepDetail(),
                ],
            },
            {
                id: NavigationTypes.AUDIOS,
                value: NavigationTypes.AUDIOS,
                title: "Audios",
                icon: <GraphicEqIcon fontSize="large" />,
                route: linkProvider.react.audios().index(),
                lastVisited: audiosStack?.lastVisited
                    ? audiosStack?.lastVisited
                    : "",
                extraRoutes: [
                    linkProvider.react.audios().listing(),
                    linkProvider.react.audios().detail(),
                ],
            },
            {
                id: NavigationTypes.VIDEOS,
                value: NavigationTypes.VIDEOS,
                title: "Videos",
                icon: <FontAwesomeIcon icon={["fas", "video"]} />,
                route: linkProvider.react.videos().index(),
                lastVisited: videosStack?.lastVisited
                    ? videosStack?.lastVisited
                    : "",
                extraRoutes: [
                    linkProvider.react.videos().listing(),
                    linkProvider.react.videos().detail(),
                ],
            },
            {
                id: NavigationTypes.COURSES,
                value: NavigationTypes.COURSES,
                title: "Courses",
                icon: <FontAwesomeIcon icon={["fas", "award"]} />,
                route: linkProvider.react.courses().index(),
                lastVisited: (coursesStack?.lastVisited as string)
                    ? (coursesStack?.lastVisited as string)
                    : "",
                extraRoutes: [
                    linkProvider.react.courses().index(),
                    linkProvider.react.courses().detail(),
                    linkProvider.react.courses().mediaStepDetail(),
                ],
            },
            {
                id: NavigationTypes.MORE,
                value: NavigationTypes.MORE,
                title: "More",
                icon: <FontAwesomeIcon icon={["fas", "ellipsis"]} />,
                route: linkProvider.react.more().index(),
                lastVisited: moreStack?.lastVisited
                    ? moreStack?.lastVisited
                    : "",
                extraRoutes: moreTabExtraRoutes,
            },
        ];
        if (navId)
            return items.map((i) => {
                if (i.id === navId) i.extraRoutes.push(navPath);
                return i;
            });
        return items;
    };

    const items = getNavItems();

    const findActiveTab = () => {
        return items?.find((i) => {
            const path = window.location.pathname;
            let selected = i.route === path;

            if (!selected && i.extraRoutes && i.extraRoutes.length) {
                selected = i.extraRoutes.some((r) => {
                    const match = matchPath(path, {
                        path: r,
                    });
                    if (match) {
                        return true;
                    }
                    return false;
                });
            }

            return selected;
        })?.value;
    };

    const handleSwitchTab = (item: NavItem) => {
        const activeTab = findActiveTab();

        let payload = { lastVisited: "" };
        if (
            history.location.pathname ===
            linkProvider.react.downloadingQueue().index()
        ) {
            payload = {
                lastVisited: `${history.location.pathname}`,
            };
        } else {
            payload = {
                lastVisited: `${history.location.pathname}${history.location.search}`,
            };
        }

        let redirectToMore: boolean = false;
        if (activeTab === NavigationTypes.HOME) {
            setGlobalHomeStack(payload);
        }
        if (activeTab === NavigationTypes.AUDIOS) {
            setGlobalAudiosStack(payload);
        }
        if (activeTab === NavigationTypes.VIDEOS) {
            setGlobalVideosStack(payload);
        }
        if (activeTab === NavigationTypes.COURSES) {
            setGlobalCoursesStack(payload);
        }
        if (activeTab === NavigationTypes.MORE) {
            if (payload.lastVisited.includes("?backNav=true")) {
                setGlobalMoreStack({
                    lastVisited: `${history.location.pathname}`,
                });
            } else {
                setGlobalMoreStack(payload);
            }
            const matched = moreTabExtraRoutes.some((x) => {
                const match = matchPath(window.location.pathname, {
                    path: x,
                });

                return !!match;
            });
            if (matched) {
                redirectToMore = true;
            }
        }
        setGlobalHomeStack({
            isHomeTrack: false,
        });

        history.push(
            item.lastVisited && !redirectToMore ? item.lastVisited : item.route,
        );

        const allowsNotification = !!window.Notification;

        if (allowsNotification && Notification.permission === "default") {
            Notification.requestPermission().then((perm) => {
                if (perm === "granted") {
                    onGettingPermission();
                }
            });
        }
    };

    const disableBuyBtn =
        (method === PaymentMethod.MY_CREDITS && availableCredits <= 0) ||
        isBuyDisabled;

    const isShowProgressBar =
        showDownloadProgress &&
        !history.location.pathname.includes(
            `${linkProvider.react.downloadingQueue().index()}`,
        );

    return (
        <div
            className={clsx(className, !disableLayout && classes.root)}
            {...rest}
        >
            <ForegroundTasks />
            {isShowProgressBar && (
                <div>
                    <AppCompletionProgress
                        title={`${downloadContentName} is ${downloadContentPercentage}% downloaded.`}
                        value={downloadContentPercentage}
                    />
                </div>
            )}
            {!disableLayout && (
                <RecommendationsManager>
                    <div
                        style={{
                            paddingTop: 0,
                        }}
                        className={clsx(classes.content)}
                    >
                        {children}
                        {paginationInfo &&
                            paginationInfo.perPage < paginationInfo.total && (
                                <div
                                    className={classes.navLoadMore}
                                    ref={lastElementRef}
                                >
                                    <Typography variant="body1">
                                        {messaging.pagination.loadMore}
                                    </Typography>
                                </div>
                            )}
                    </div>
                </RecommendationsManager>
            )}

            {disableLayout && <>{children}</>}
            {!disableLayout && (
                <>
                    <Paper
                        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
                        elevation={5}
                        className={classes.wrapperPaper}
                    >
                        {(activeGiftItem?.giftPurchasedEnabled
                            ? activeGiftItem.quantity !== 0 &&
                              purchaseEnabled &&
                              !dialogs?.selectMembers
                            : isPurchasing) && (
                            <>
                                <div className={classes.buyBtnWrapper}>
                                    <div className={classes.horizontalLine} />
                                    <div
                                        className={clsx(
                                            classes.totalPriceWrapper,
                                            classes.grey600,
                                        )}
                                    >
                                        <Typography variant="body1">
                                            {purchaseNavText}
                                        </Typography>
                                    </div>
                                    <div className={classes.horizontalLine} />
                                </div>
                                <BottomNavigation
                                    classes={{
                                        root: classes.navRoot,
                                    }}
                                    showLabels
                                >
                                    <AppButton
                                        onClick={onBuyClick}
                                        disabled={disableBuyBtn}
                                        buttonVariant={
                                            disableBuyBtn
                                                ? "disabled-outlined"
                                                : "grey-outlined"
                                        }
                                        rounded
                                        variant="text"
                                    >
                                        {method === PaymentMethod.CARD
                                            ? "BUY"
                                            : "REDEEM"}
                                    </AppButton>
                                </BottomNavigation>
                            </>
                        )}
                        {(!isPurchasing || dialogs?.selectMembers) && (
                            <>
                                <BottomNavigation
                                    classes={{
                                        root: classes.navRoot,
                                    }}
                                    showLabels
                                    value={findActiveTab()}
                                >
                                    {items?.map((item) => {
                                        return (
                                            <BottomNavigationAction
                                                classes={{
                                                    root: classes.icon,
                                                    selected:
                                                        classes.btnSelected,
                                                }}
                                                key={item.id}
                                                value={item.value}
                                                icon={item.icon}
                                                aria-label={item.title}
                                                onClick={() =>
                                                    handleSwitchTab(item)
                                                }
                                            />
                                        );
                                    })}
                                </BottomNavigation>
                            </>
                        )}
                    </Paper>
                </>
            )}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: theme.footer.height,
            height: "100vh",
        },
        navRoot: {
            height: `${theme.footer.height} !important`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
        navLoadMore: {
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            height: `calc(${theme.footer.height} - 40px)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        icon: {
            color: theme.palette.grey[400],
            fontSize: theme.spacing(3),
        },

        btnSelected: {
            color: `${theme.palette.primary.main} !important`,
            fontSize: theme.spacing(3),
        },

        content: {
            padding: theme.spacing(0, 1),
            paddingBottom: `calc(${theme.footer.height} + ${theme.spacing(2)})`,
            "-webkit-overflow-scrolling": "touch",
        },

        totalPriceWrapper: {
            background:
                theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
            padding: theme.spacing(1, 2),
            borderRadius: theme.spacing(3),
        },

        horizontalLine: {
            flexGrow: 1,
            height: "3px",
            background:
                theme.palette.mode === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        buyBtnWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "-20px",
            backgroundColor: theme.palette.background.paper,
        },
        loadMoreWarpper: {
            display: "flex",
            justifyContent: "center",
        },
        wrapperPaper: {
            backgroundColor: `${theme.palette.background.paper} !important`,
        },
    }),
);

export interface LayoutProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {
    children?: React.ReactNode;
    isPurchasing?: boolean;
    purchaseEnabled?: boolean;
    purchaseNavText?: string;
    method?: PaymentMethod;
    disableLayout?: boolean;
    onBuyClick?: () => void;
    onLastContentHit?: () => void;
    isBuyDisabled?: boolean;
    activeGiftItem?: {
        title: string;
        giftPurchasedEnabled: boolean;
        quantity: number;
    };
    dialogs?: {
        transactionComplete: boolean;
        transactionConfirmation: boolean;
        selectMembers: boolean;
    };
    availableCredits?: number;
    paginationInfo?: PaginationInfo;
    navId?: string;
    navPath?: string;
    isCaptureScroll?: boolean;
    setScrollValue?: (scrollPostion: number) => void;
}

export interface NavItem {
    id: string;
    value: NavigationTypes;
    icon: React.ReactNode;
    route?: string;
    lastVisited?: string;
    title?: string;
    extraRoutes?: string[];
}
