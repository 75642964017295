import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import qs from "qs";
import { useSnackbar } from "notistack";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Grid,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { GraphicEq as GraphicEqIcon } from "@mui/icons-material";

import {
    useGiftRemainingQuantity,
    useGlobalGifts,
    useAudioGifts,
    useSendGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
    useProsGiftRemainingQuantity,
} from "JS/React/Hooks/Gifts";
import {
    useAvailableCredits,
    usePurchaseByCard,
    useAddContentToCart,
    useRedeemByCredits,
    useRedeemGiftByCredits,
} from "JS/React/Hooks/Purchase";
import {
    useGlobalAudios,
    useAudios,
    useAudioDetails,
    useGlobalAudioDetails,
} from "JS/React/Hooks/Audio";
import {
    Content,
    Gifts,
    RecommendationSource,
    AddToDownloadOptions,
    GiftsTypes,
    EventNames,
    EventCategories,
    EventActions,
    FirebaseEventAction,
    MiniPlayerOptions,
    ContentWithMedia,
    ContentMedia,
} from "JS/Models";
import {
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentGift,
} from "JS/Models/Firebase/GiftDashboard";
import { getPublicUrl } from "JS/Helpers/S3Helper";
import { AppHeader } from "JS/React/Components/AppHeader";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { AppButton } from "JS/React/Components/AppButton";
import { ContentPurchase } from "JS/React/Components/ContentPurchase/ContentPurchase";
import { PaymentMethod } from "JS/React/Components/ContentPurchase/PaymentMethodRadio";
import { GiftQuantity } from "JS/React/Components/ContentPurchase/GiftQuantity";
import {
    getDownloadedPartsNids,
    hasAttachSku,
    isBundleInProgress,
    isRedeemable,
    isSufficientCreditAvailable,
    recPlayerAnalyticsActionAndEvent,
    sortPlaylists,
} from "JS/Helpers";
import { SelectMembersDialog } from "JS/React/Components/ContentPurchase/SelectMembersDialog";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import { messaging } from "JS/Helpers/UserMessaging";
import { useRouting } from "JS/React/Hooks/Routes";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    useCheckContentDownloaded,
    useHandleMediaGifts,
    useSearchedLosMembers,
    useVerifyAwsKeys,
} from "JS/React/Hooks/Media";
import { useGlobalNavStack } from "JS/React/Hooks/NavStack";
import { usePlaylistCommonHandlers } from "JS/React/Hooks/Playlist";
import {
    useGetFavorites,
    useGlobalFavorites,
    useMarkFavorites,
    useUnMarkFavorites,
} from "JS/React/Hooks/Favorites";
import { usePlayerHandlers } from "JS/React/Hooks/MediaPlayer";
import { useDownloadingStatus } from "JS/React/Hooks/Downloading";
import { useMiniPlayerHandlers } from "JS/React/Hooks/MiniPlayer";
import { useResumeRecentlyAudio } from "JS/React/Hooks/ResumeRecently";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import {
    addToDownloadQueue,
    getDownloadQueueLength,
    isInDownloadQueue,
    DownloadStatus,
} from "JS/Helpers/ContentDownloadHelper";
import { useAppDispatch, useAppSelector } from "JS/Redux/Store";
import { InputDialog } from "JS/React/Components/InputDialog";
import { ListDialog } from "JS/React/Components/ListDialog";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { config } from "JS/Config";
import { setDownloadedContentNids } from "JS/Redux/download";

import { PLAYER_PROGRESS_INTERVAL, appConstants } from "JS/Helpers/Contants";
import { DownloadDisabled } from "JS/React/Components/DownloadDisabled";
import { DownloadWarningDialog } from "JS/React/Components/DownloadWarningDialog";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { getCurrentUrl } from "JS/Helpers/MiniPlayerHelper";
import { deleteAudioById, getAudioById } from "JS/Database/Audio";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
import { AppMenuListing } from "JS/React/Components/AppMenuListing";
import { RenderIf } from "JS/React/Components/Wrapper/RenderIf";
import { FavoritesTypes } from "../Favorites";
import { Layout } from "../Layout";
import { AudioCategoryTitle } from ".";
import {
    AttachSkuMenu,
    convertToAttachSkuArray,
} from "../AttachedSku/AttachSkuMenu";

export const AudioContentDetail = (props: AudioContentDetailProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;

    const history = useHistory();
    const params: any = useParams();
    const { linkProvider } = useRouting();
    const { homeStack, setGlobalAudiosStack } = useGlobalNavStack();
    const { contentId, categoryId, isFavorite, isAutoPlay } = params;
    const { enqueueSnackbar } = useSnackbar();
    const [downloadedAudioUrl, setDownloadedAudioUrl] = useState<string>("");
    const [isAudioDownloaded, setIsAudioDownloaded] = useState<boolean>(false);
    const [addedToDownloadQueue, setAddedToDownloadQueue] =
        useState<boolean>(false);
    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [audioUrl, setAudioUrl] = useState("");
    const [queueLimitReached, setQueueLimitReached] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [thumbnail, setThumbnail] = useState<string>(null);

    const { audios, setGlobalAudios, isAudioUpdateRequired } =
        useGlobalAudios();

    const { audioDetails } = useGlobalAudioDetails(contentId);

    const inProgressNid = useAppSelector(
        (state) => state.download.inProgressNid,
    );
    const downloadedContentNids = useAppSelector(
        (state) => state.download.downloadedContentNids,
    );
    const dispatch = useAppDispatch();

    const isRecommended = categoryId === AudioCategoryTitle.RECOMMENDED;

    const { audioGifts, receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();

    const {
        loading: receivedMemberAudioGiftsLoading,
        loaded: receivedMemberGiftsLoaded,
        isGift,
    } = useReceivedMemberAudioGifts(
        !!receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0,
    );
    const {
        loading: receivedProspectAudioGiftsLoading,
        loaded: receivedProspectGiftsLoaded,
    } = useReceivedProspectAudioGifts(
        !!receivedProspectAudioGifts && receivedProspectAudioGifts.length > 0,
    );

    const audio: Gifts | ContentWithMedia = useMemo(() => {
        if (audios?.filter((audio) => audio.nid === contentId).length > 0) {
            return {
                ...audios?.find((audio) => audio.nid === contentId),
                ...audioDetails,
            };
        } else if (
            receivedMemberAudioGifts?.filter((audio) => audio.nid === contentId)
                .length > 0
        ) {
            return receivedMemberAudioGifts?.find(
                (audio) => audio.nid === contentId,
            );
        } else {
            return receivedProspectAudioGifts?.find(
                (audio) => audio.nid === contentId,
            );
        }
    }, [
        audios,
        receivedMemberAudioGifts,
        contentId,
        receivedProspectAudioGifts,
        audioDetails,
    ]);

    const giftDashboardData = useMemo(() => {
        return toSentGift(audio);
    }, [audio]);

    const analyticsData = (action?: EventActions) => {
        const { sku_id: skuId, nid: nId, title: contentTitle } = audio;
        return {
            action,
            contentTitle,
            skuId,
            nId,
            category: audio?.isReceived
                ? EventCategories.GIFT_AUDIOS
                : EventCategories.AUDIOS,
        };
    };

    const shortcutGift = () => {
        const giftSku =
            activeGiftItem.title === GiftsTypes.MEMBER
                ? "member_gift_sku" in audio && audio.member_gift_sku
                : "prospect_gift_sku" in audio && audio.prospect_gift_sku;
        return {
            ...audioGifts?.find((x) => x.sku_id === giftSku),
            sku_id: giftSku,
        };
    };

    const fetchUrls = (
        options: {
            media_url_prefix: string;
            media_url_postfix: string;
            media_file_name: string;
        },
        skipThumb: boolean = false,
    ) => {
        const { media_file_name, media_url_postfix, media_url_prefix } =
            options;
        verifyAwsKeys().then(() => {
            if (!skipThumb) {
                fetchThumbnails();
            }
            getPublicUrl(
                media_url_prefix,
                media_url_postfix,
                media_file_name,
            ).then((signedURL) => setMediaURL(signedURL));
        });
    };

    const fetchDownloadedAudio = () => {
        if (audio) {
            getAudioById(`${config.user.memberId}-${audio.nid}`)
                .then((res) => {
                    if (res) {
                        setIsReady(false);
                        setIsAudioDownloaded(true);
                        setAddedToDownloadQueue(false);
                        const url = URL.createObjectURL(res.blob);
                        setDownloadedAudioUrl(url);
                    }
                })
                .catch(() => {
                    setIsReady(true);
                    setIsAudioDownloaded(false);
                });
        }
    };

    const {
        playerRef,
        setIsReady,
        handleRecentlyPlayed,
        handleContentCompleted,
        onMediaPlayerReady,
        onPlayerProgress,
        isReadyToPlay,
        setIsReadyToPlay,
    } = useResumeRecentlyAudio(audio, contentId);

    const isFavoritesTab = isFavorite && JSON.parse(isFavorite?.toLowerCase());

    const { favoritesAudios, setGlobalFavoritesAudios } = useGlobalFavorites();
    const { loading: favoritesLoading, refetch: fetchFavorites } =
        useGetFavorites(!!favoritesAudios && favoritesAudios.length > 0);

    const isFavoriteAudio =
        favoritesAudios?.findIndex((x) => x.nid === audio?.nid) !== -1;

    const { markFavoriteByNid, loading: markFavLoading } = useMarkFavorites();
    const { unMarkFavoriteByNid, loading: unMarkFavLoading } =
        useUnMarkFavorites();

    const { loading, loaded: audiosLoaded } = useAudios(
        !!audios && audios.length > 0,
        isAudioUpdateRequired,
    );

    const { loading: detailLoading } = useAudioDetails(
        contentId,
        !audio?.isReceived,
    );

    const { loading: audioGiftsLoading } = useAudioGifts(
        !!audioGifts && audioGifts.length > 0,
    );

    const {
        availableCredits,
        loading: availableCreditsLoading,
        getAvailableAudioCredits,
    } = useAvailableCredits();
    const { redeemByCredits, loading: redeemingContent } = useRedeemByCredits();
    const { redeemGiftByCredits, loading: redeemingGiftContent } =
        useRedeemGiftByCredits();

    const { addToCart, cart } = useAddContentToCart();
    const { purchaseByCard, loading: purchasingContent } = usePurchaseByCard();
    const { queryParams, recPlayerAnalytics } = useRecommendationAnalytics();
    const { isMixedContent, scrollTo = "0" } = queryParams;
    useCheckContentDownloaded(
        audio?.nid,
        inProgressNid,
        "audio",
        setIsAudioDownloaded,
        setDownloadedAudioUrl,
    );

    useEffect(() => {
        if (!!isAudioDownloaded) setIsReady(false);
    }, [isAudioDownloaded]);

    const [mediaURL, setMediaURL] = useState("");
    const [purchaseEnabled, setPurchaseEnabled] = useState(false);
    const [isPurchasedNow, setIsPurchasedNow] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const [giftItem, setGiftItem] = useState<Gifts>(null);

    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
        audio?.is_redeemable_by_tokens === "1" &&
            availableCredits !== 0 &&
            availableCredits >= +audio?.number_of_tokens
            ? PaymentMethod.MY_CREDITS
            : PaymentMethod.CARD,
    );

    const audioGiftSku = (giftType: GiftsTypes) => {
        if (audio && !audio?.isReceived) {
            if (giftType === GiftsTypes.MEMBER)
                return "member_gift_sku" in audio && audio.member_gift_sku;
            return "prospect_gift_sku" in audio && audio.prospect_gift_sku;
        } else {
            return "";
        }
    };

    const {
        giftQuantity: giftMemberQuantity,
        refetch: fetchGiftMemberQuanity,
        loading: loadingGiftMemeberQuanity,
    } = useGiftRemainingQuantity(
        audioGiftSku(GiftsTypes.MEMBER),
        !(audio && !audio?.isReceived && "member_gift_sku" in audio),
    );

    const {
        activeGiftItem,
        dialogs,
        selectedMembers,
        anchorSendGift,
        openSendGift,
        setDialogs,
        setActiveGiftItem,
        onQuantityChange,
        onSendContent,
        onToggleItemSelect,
        onCloseSelectMembers,
        setAnchorSendGift,
        onGiftSendMenuItem,
        onSendItemsClose,
    } = useHandleMediaGifts(giftMemberQuantity, setIsReady, setPurchaseEnabled);

    const {
        addToPlaylistLoading,
        addToPlaylistSuccess,
        createLoading,
        playlistsLoading,
        isCreatePlaylist,
        openPlaylistSelection,
        playlistsInStore,
        createClick,
        handlePlaylistClick,
        addAudio,
        setAnchorPlaylist,
        setCreatePlaylist,
    } = usePlaylistCommonHandlers(audio);

    useEffect(() => {
        setPaymentMethod(
            isRedeemable(
                audio,
                availableCredits,
                activeGiftItem?.giftPurchasedEnabled
                    ? giftItem?.content_price
                    : "0",
            )
                ? PaymentMethod.MY_CREDITS
                : PaymentMethod.CARD,
        );
    }, [
        audio?.is_redeemable_by_tokens,
        activeGiftItem.giftPurchasedEnabled,
        availableCredits,
        audio?.number_of_tokens,
    ]);

    const {
        giftQuantity: giftProspectQuantity,
        refetch: fetchGiftProspectQuanity,
        loading: loadingGiftProspectQuanity,
    } = useProsGiftRemainingQuantity(
        audioGiftSku(GiftsTypes.PROSPECT),
        !(audio && !audio?.isReceived && "prospect_gift_sku" in audio),
    );
    const { handleChangeSeek } = usePlayerHandlers(playerRef);
    const { logFirebaseEvent } = useFirebaseLogger();
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { searchQueryLos, searchLosResults, setSearchQueryLos } =
        useSearchedLosMembers();
    const { sendGiftToUser, loading: sendingGifts } = useSendGifts();

    const isMediaPlayable =
        audio?.is_purchased ||
        audio?.publish_free === "1" ||
        audio?.publish_free === "true" ||
        audio?.isReceived;

    const isBundle = +audio?.no_of_files > 1;

    const audioLoaded =
        audiosLoaded &&
        receivedProspectGiftsLoaded &&
        receivedMemberGiftsLoaded;

    useEffect(() => {
        if (!audio && audioLoaded) {
            history.goBack();
        }
    }, [audio, audioLoaded]);

    const getDashboardActionDetails = () => {
        return getPlayerPlayedTimeToLog(playerRef);
    };

    const { miniPlayer, onClickMiniPlayer } = useMiniPlayerHandlers();

    const isReceivedMemberAudio = isGift(contentId);

    const { logGiftOpen, logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        SentGiftType.AUDIO,
        audio && "sender" in audio ? audio?.sender?.member_id : null,
        isReceivedMemberAudio && isPlaying && !miniPlayer?.url,
        getDashboardActionDetails,
    );

    useEffect(() => {
        if (audio && !detailLoading) {
            const { media_url_prefix, media_url_postfix, media_file_name } =
                "media" in audio ? audio.media[0] : audio;
            if (isBundle) {
                getDownloadedPartsNids(audio.nid)
                    .then((res) => {
                        if (res.length === +audio?.no_of_files) {
                            setIsReady(false);
                            setIsAudioDownloaded(true);
                            setAddedToDownloadQueue(false);
                        }
                    })
                    .catch((err) => {
                        setIsReady(true);
                        setIsAudioDownloaded(false);
                    });
            } else {
                fetchDownloadedAudio();
            }

            logFirebaseEvent(
                audio?.isReceived
                    ? EventNames.GIFT_AUDIO_OPENED
                    : EventNames.AUDIO_OPENED,
                analyticsData(EventActions.OPEN),
            );

            fetchUrls(
                {
                    media_url_prefix,
                    media_url_postfix,
                    media_file_name,
                },
                false,
            );
        }
    }, [audio?.media, detailLoading]);

    useEffect(() => {
        if (isReceivedMemberAudio && audio) logGiftOpen();
    }, [isReceivedMemberAudio, audio?.nid]);

    useEffect(() => {
        let interval;
        if (
            audio &&
            (inProgressNid.includes(audio?.nid) || addedToDownloadQueue)
        ) {
            const { nid } = audio;
            interval = setInterval(() => {
                if (!isInDownloadQueue(nid)) {
                    setAddedToDownloadQueue(false);
                }
                if (!isBundle) {
                    fetchDownloadedAudio();
                } else {
                    getDownloadedPartsNids(audio.nid)
                        .then((res) => {
                            if (res.length === audio?.media?.length) {
                                setIsReady(false);
                                setIsAudioDownloaded(true);
                                setAddedToDownloadQueue(false);
                            }
                        })
                        .catch((err) => {
                            setIsReady(true);
                            setIsAudioDownloaded(false);
                        });
                }

                if (isAudioDownloaded) {
                    clearInterval(interval);
                }
            }, 5000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [audio, addedToDownloadQueue, isAudioDownloaded]);

    const fetchThumbnails = async () => {
        if (!thumbnail) {
            const thumb = await getPublicUrl(
                audio.image_url_prefix,
                audio.image_url_postfix,
                audio.image_file_name,
            );

            if (!!thumb) {
                setThumbnail(thumb);
            } else {
                setThumbnail("a");
            }
        }
    };

    const handleAudioStartedPlaying = () => {
        if (audio) {
            if (!isAudioDownloaded) {
                logFirebaseEvent(
                    audio?.isReceived
                        ? EventNames.GIFT_AUDIO_LIVESTREAM
                        : EventNames.AUDIO_LIVE_STREAM,
                    analyticsData(EventActions.LIVE_STREAM),
                );
                if (isRecommended) {
                    recPlayerAnalytics("live-stream", audio as Content);
                }
            } else {
                logFirebaseEvent(
                    audio?.isReceived
                        ? EventNames.GIFT_AUDIO_PLAYED
                        : EventNames.AUDIO_PLAYED,
                    analyticsData(EventActions.PLAY),
                );
                if (isRecommended) {
                    recPlayerAnalytics("played", audio as Content);
                }
            }
            handleRecentlyPlayed();
        }
    };

    const purchaseText = useMemo(() => {
        if (activeGiftItem.giftPurchasedEnabled) {
            return paymentMethod === PaymentMethod.MY_CREDITS
                ? `Credits Required:  ${
                      +giftItem?.number_of_tokens * activeGiftItem?.quantity ||
                      0
                  }`
                : `Total: $ ${(
                      +giftItem?.content_price * activeGiftItem?.quantity || 0
                  ).toFixed(2)}`;
        } else {
            return paymentMethod === PaymentMethod.MY_CREDITS
                ? `Credits Required:  ${+audio?.number_of_tokens}`
                : `Total: $ ${(+audio?.content_price || 0).toFixed(2)}`;
        }
    }, [activeGiftItem, giftItem, paymentMethod]);

    const onBuyGiftContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            if (activeGiftItem?.giftPurchasedEnabled) {
                const shortcut = shortcutGift();
                const firebaseEvent: Partial<FirebaseEventAction> = {
                    nId: shortcut?.nid,
                    contentTitle: shortcut?.title,
                    category: EventCategories.GIFT_AUDIOS,
                };
                redeemGiftByCredits(
                    shortcut?.sku_id,
                    activeGiftItem?.quantity,
                    firebaseEvent,
                ).then((val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete: true,
                        });
                        if (activeGiftItem?.giftPurchasedEnabled) {
                            if (activeGiftItem?.title === GiftsTypes.MEMBER) {
                                fetchGiftMemberQuanity(
                                    (audio as Content)?.member_gift_sku,
                                );
                            } else {
                                fetchGiftProspectQuanity(
                                    (audio as Content)?.prospect_gift_sku,
                                );
                            }
                        }
                        getAvailableAudioCredits();
                        !activeGiftItem?.giftPurchasedEnabled &&
                            setPurchaseEnabled(false);
                    }
                });
            }
        }
        if (paymentMethod === PaymentMethod.CARD) {
            if (activeGiftItem?.giftPurchasedEnabled) {
                const shortcut = shortcutGift();
                const firebaseEvent: Partial<FirebaseEventAction> = {
                    nId: shortcut?.nid,
                    contentTitle: shortcut?.title,
                    category: EventCategories.GIFT_AUDIOS,
                };
                purchaseByCard(
                    cart.cartId,
                    activeGiftItem.quantity,
                    shortcut?.sku_id,
                    firebaseEvent,
                ).then((val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete:
                                activeGiftItem?.giftPurchasedEnabled,
                        });
                        if (activeGiftItem?.giftPurchasedEnabled) {
                            if (activeGiftItem?.title === GiftsTypes.MEMBER) {
                                fetchGiftMemberQuanity(
                                    (audio as Content)?.member_gift_sku,
                                );
                            } else {
                                fetchGiftProspectQuanity(
                                    (audio as Content)?.prospect_gift_sku,
                                );
                            }
                        }
                        !activeGiftItem?.giftPurchasedEnabled &&
                            setPurchaseEnabled(false);
                    }
                });
            }
        }
    };

    const onBuyContent = () => {
        if (paymentMethod === PaymentMethod.MY_CREDITS) {
            const firebaseEvent: Partial<FirebaseEventAction> = {
                nId: audio?.nid,
                contentTitle: audio?.title,
                category: EventCategories.AUDIOS,
            };
            redeemByCredits(audio?.sku_id, firebaseEvent).then((val) => {
                if (val.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: false,
                    });
                    const toSaveAudios: Content[] = audios.map((d) => {
                        if (d.nid === contentId) {
                            return {
                                ...d,
                                is_purchased: true,
                                publish_free: "1",
                                content_price: 0.0,
                            };
                        } else {
                            return d;
                        }
                    });
                    setGlobalAudios(toSaveAudios);
                    getAvailableAudioCredits();
                    setPurchaseEnabled(false);
                    setIsPurchasedNow(true);
                }
            });
        }
        if (paymentMethod === PaymentMethod.CARD) {
            const firebaseEvent: Partial<FirebaseEventAction> = {
                nId: audio?.nid,
                contentTitle: audio?.title,
                category: EventCategories.AUDIOS,
            };
            purchaseByCard(cart.cartId, 1, audio.sku_id, firebaseEvent).then(
                (val) => {
                    if (val.response?.status) {
                        setDialogs({
                            ...dialogs,
                            transactionConfirmation: false,
                            transactionComplete:
                                activeGiftItem?.giftPurchasedEnabled,
                        });
                        const toSaveAudios: Content[] = audios.map((d) => {
                            if (d.nid === contentId) {
                                return {
                                    ...d,
                                    is_purchased: true,
                                    publish_free: "1",
                                    content_price: 0.0,
                                };
                            } else {
                                return d;
                            }
                        });
                        setGlobalAudios(toSaveAudios);
                        setPurchaseEnabled(false);
                        setIsPurchasedNow(true);
                    }
                },
            );
        }
    };

    const onBuyConfirm = async () => {
        if (paymentMethod === PaymentMethod.CARD) {
            addToCart(
                activeGiftItem?.giftPurchasedEnabled
                    ? audioGiftSku(activeGiftItem.title)
                    : audio.sku_id,
                activeGiftItem?.giftPurchasedEnabled
                    ? activeGiftItem.quantity
                    : 1,
            ).then((res) => {
                if (res?.response?.status) {
                    setDialogs({
                        ...dialogs,
                        transactionConfirmation: true,
                    });
                }
            });
        } else {
            setDialogs({
                ...dialogs,
                transactionConfirmation: true,
            });
        }
    };

    const onGiftMenuItemClick = (item: GiftsTypes, sku: string) => {
        const selectedItem = audioGifts?.find((x) => x.sku_id === sku);
        if (selectedItem) {
            setGiftItem(selectedItem);
            setPurchaseEnabled(true);
            setActiveGiftItem({
                title: item,
                giftPurchasedEnabled: true,
                giftPurchasedSuccess: false,
                quantity: 1,
            });
            setAnchorEl(null);
        } else {
            enqueueSnackbar(messaging.common.noAccess, {
                variant: "warning",
            });
        }
    };

    const onSendSelectedMembers = async () => {
        if (selectedMembers.length >= 1) {
            const shortcut = shortcutGift();
            if (activeGiftItem.title === GiftsTypes.MEMBER) {
                sendGiftToUser(
                    [
                        {
                            sku: shortcut?.sku_id,
                            nid: shortcut?.nid,
                            title: shortcut?.title,
                            category: EventCategories.GIFT_AUDIOS,
                        },
                    ],
                    selectedMembers,
                ).then((val) => {
                    if (val?.response?.status === true) {
                        fetchGiftMemberQuanity(shortcut?.sku_id);
                        onSendItemsClose();
                    }
                });
            }
        } else {
            enqueueSnackbar(messaging?.gifts?.selectAtLeastOneMember, {
                variant: "warning",
            });
        }
    };

    const playClick = () => {
        if (!isMixedContent) {
            if (categoryId === FavoritesTypes.FAVAUDIOS) {
                history.push(
                    linkProvider.react
                        .favorites()
                        .favoriteBundleAudios(
                            contentId,
                            categoryId,
                            audio?.isReceived.toString(),
                            { scrollTo: +scrollTo },
                        ),
                );
            } else {
                history.push(
                    linkProvider.react
                        .bundle()
                        .audios(
                            contentId,
                            categoryId,
                            audio?.isReceived.toString(),
                            { scrollTo: +scrollTo },
                        ),
                );
            }
        } else {
            history.push(
                linkProvider.react
                    .mixedContent()
                    .bundleDetail(
                        contentId,
                        categoryId,
                        audio?.isReceived.toString(),
                        { isMixedContent: true, scrollTo: scrollTo.toString() },
                    ),
            );
        }
    };

    const handleFavouriteClick = (
        e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    ) => {
        if (isFavoriteAudio) {
            unMarkFavoriteByNid(audio?.nid, analyticsData(null))
                .then((res) => {
                    if (res.status && res.key === 0) {
                        let tempArray = [...favoritesAudios];
                        tempArray?.splice(
                            tempArray?.findIndex((x) => x.nid === audio.nid),
                            1,
                        );
                        setGlobalFavoritesAudios(tempArray);
                        enqueueSnackbar(messaging.favorites.success, {
                            variant: "success",
                        });
                    } else if (res.status && res.key === 85) {
                        fetchFavorites(true, true);
                    } else {
                        enqueueSnackbar(messaging.favorites.error, {
                            variant: "error",
                        });
                    }
                })
                .catch((ex) => {
                    enqueueSnackbar(messaging.favorites.error, {
                        variant: "error",
                    });
                });
        } else {
            markFavoriteByNid(audio?.nid, analyticsData(null))
                .then((res) => {
                    if (res.status && res.key === 0) {
                        let tempArray = [...favoritesAudios];
                        tempArray.push(audio as Content);
                        setGlobalFavoritesAudios(tempArray);
                        enqueueSnackbar(messaging.favorites.success, {
                            variant: "success",
                        });
                    } else if (res.status && res.key === 85) {
                        fetchFavorites(true, true);
                    } else {
                        enqueueSnackbar(messaging.favorites.error, {
                            variant: "error",
                        });
                    }
                })
                .catch((ex) => {
                    enqueueSnackbar(messaging.favorites.error, {
                        variant: "error",
                    });
                });
        }
    };

    const handleDownload = () => async () => {
        showWarningSnackbarIfRequired();
        setAddedToDownloadQueue(true);
        const downloadingQueueLimit =
            appConstants.downloadingQueue.downloadingQueueLimit;
        const queueLength = getDownloadQueueLength();
        if (queueLength < downloadingQueueLimit) {
            const options: AddToDownloadOptions = {
                media_url_prefix: media.media_url_prefix,
                media_url_postfix: media.media_url_postfix,
                media_file_name: media.media_file_name,
                name: audio.title,
                type: audio.isReceived ? "giftAudioBundle" : "audioBundle",
                nid: audio.nid,
                skuId: audio.sku_id,
                description: audio.description,
                release_date: audio.release_date,
            };
            if (isBundle) {
                const downloadedContentNids = await getDownloadedPartsNids(
                    audio.nid,
                );
                if (
                    queueLength +
                        (+audio.no_of_files - downloadedContentNids.length) >
                    downloadingQueueLimit
                ) {
                    setQueueLimitReached(true);
                }

                options.media = audio.media;

                addToDownloadQueue(options);
            } else {
                options.type = audio.isReceived ? "giftAudio" : "audio";
                addToDownloadQueue(options);
            }
        } else {
            setQueueLimitReached(true);
            setAddedToDownloadQueue(false);
        }
    };

    const handleDelete = async (nid: string) => {
        if (!isBundle) {
            setDeleteLoader(true);
            deleteAudioById(`${config.user.memberId}-${nid}`)
                .then((res) => {
                    setIsReady(false);
                    setIsAudioDownloaded(false);
                    setAddedToDownloadQueue(false);
                    enqueueSnackbar(messaging.download.deleteSuccess, {
                        variant: "success",
                    });
                    if (downloadedContentNids && downloadedContentNids.length) {
                        const downloadedNids = [...downloadedContentNids];
                        const updatedDownloadedNids = downloadedNids?.filter(
                            (downloadedNid) =>
                                downloadedNid !==
                                `${config.user.memberId}-${nid}`,
                        );
                        dispatch(
                            setDownloadedContentNids({
                                downloadedContentNids: updatedDownloadedNids,
                            }),
                        );
                    }
                })
                .catch((err) => {
                    enqueueSnackbar(messaging.download.deleteError, {
                        variant: "error",
                    });
                })
                .finally(() => {
                    setDeleteLoader(false);
                });
        } else {
            setDeleteLoader(true);
            let isDeleted = false;
            await getDownloadedPartsNids(nid).then(async (res) => {
                if (res.length) {
                    for (let i = 0; i < res.length; i++) {
                        await deleteAudioById(res[i])
                            // eslint-disable-next-line no-loop-func
                            .then((response) => {
                                isDeleted = true;
                                setIsReady(false);
                                setIsAudioDownloaded(false);
                                setAddedToDownloadQueue(false);
                                if (
                                    downloadedContentNids &&
                                    downloadedContentNids.length
                                ) {
                                    const downloadedNids = [
                                        ...downloadedContentNids,
                                    ];
                                    const updatedDownloadedNids =
                                        downloadedNids?.filter(
                                            (nid) => nid !== res[i],
                                        );
                                    dispatch(
                                        setDownloadedContentNids({
                                            downloadedContentNids:
                                                updatedDownloadedNids,
                                        }),
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log({ err });
                            });
                        if (i === res.length - 1) {
                            if (isDeleted) {
                                enqueueSnackbar(
                                    messaging.download.deleteSuccess,
                                    {
                                        variant: "success",
                                    },
                                );
                            } else {
                                enqueueSnackbar(
                                    messaging.download.deleteError,
                                    {
                                        variant: "error",
                                    },
                                );
                            }
                            setDeleteLoader(false);
                        }
                    }
                }
            });
        }

        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const isContentLoaded =
        audio &&
        !loading &&
        !receivedMemberAudioGiftsLoading &&
        !receivedProspectAudioGiftsLoading;

    useEffect(() => {
        if (addToPlaylistSuccess) {
            const params: FirebaseEventAction = {
                ...analyticsData(EventActions.ADDED_TO_PLAYLIST),
                playlistId: addToPlaylistSuccess.playlistId,
            };
            logFirebaseEvent(
                audio.isReceived
                    ? EventNames.GIFT_AUDIO_ADDED_TO_PLAYLIST
                    : EventNames.AUDIO_ADDED_TO_PLAYLIST,
                params,
            );
            enqueueSnackbar(addToPlaylistSuccess.success, {
                variant: "success",
            });
        }
    }, [addToPlaylistSuccess, enqueueSnackbar]);

    useEffect(() => {
        if (!audioUrl || !isAudioDownloaded) {
            setAudioUrl(isAudioDownloaded ? downloadedAudioUrl : mediaURL);
        }
    }, [isAudioDownloaded, mediaURL]);

    const handlePlaylistSelected = (id: string) => {
        setAnchorPlaylist(null);
        addAudio(id, audio);
    };

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const favIconLoading =
        markFavLoading || unMarkFavLoading || favoritesLoading;
    const [isLoop, setLoop] = useState(false);

    const handleNavigateToQueue = () => {
        const payload = {
            lastVisited: `${history.location.pathname}${history.location.search}`,
        };
        setGlobalAudiosStack(payload);
        history.push(linkProvider.react.downloadingQueue().index("Audio"));
    };

    const isDownloading =
        (inProgressNid?.includes(audio?.nid) ||
            (isBundle && isBundleInProgress(contentId))) &&
        !isAudioDownloaded;

    const toggleQueueLimitReached = () => {
        setQueueLimitReached(!queueLimitReached);
    };
    const onBackClick = () => {
        if (!isMixedContent) {
            if (!homeStack?.isHomeTrack) {
                if (isFavoritesTab) {
                    history.push(
                        linkProvider.react.favorites().favoriteAudiosListing({
                            scrollTo: +scrollTo,
                        }),
                    );
                } else {
                    if (isRecommended) {
                        history.push(
                            linkProvider.react.recommendations().index(),
                        );
                    } else if (categoryId === AudioCategoryTitle.INDEX) {
                        history.push(
                            linkProvider.react
                                .audios()
                                .index({ scrollTo: scrollTo ? +scrollTo : 0 }),
                        );
                    } else {
                        history.push(
                            linkProvider.react.audios().listing(categoryId, {
                                scrollTo: scrollTo ? +scrollTo : 0,
                            }),
                        );
                    }
                }
            } else {
                history.goBack();
            }
        } else {
            history.push(
                linkProvider.react.mixedContent().index(null, {
                    scrollTo: scrollTo.toString(),
                }),
            );
        }
    };

    const { isInsufficeintCredits, isRedeemDisabled } =
        isSufficientCreditAvailable(
            paymentMethod,
            availableCredits,
            activeGiftItem.quantity,
            +giftItem?.number_of_tokens,
        );

    const media = useMemo(
        () => (audio?.media?.length ? audio?.media[0] : audio) as ContentMedia,
        [audio],
    );

    const { downloadStatus, showWarningSnackbarIfRequired } =
        useDownloadingStatus(
            `${media?.media_url_postfix}/${media?.media_file_name}`,
            media?.media_url_prefix,
        );

    const miniplayerOptions = useRef<MiniPlayerOptions>(null);

    useMemo(() => {
        miniplayerOptions.current = {
            mediaUrl: audioUrl,
            thumbnail: thumbnail,
            title: audio?.title,
            content: audio,
            singleLoop: isLoop,
            isPlaying,
            parentLink: getCurrentUrl(),
            contentType:
                "content_item_type_name" in audio &&
                !!audio?.content_item_type_name
                    ? audio?.content_item_type_name
                    : "MP3",
            recommendation: isRecommended
                ? {
                      displayCount: queryParams?.displayCount,
                      recId: queryParams?.recId,
                      source: queryParams?.source,
                  }
                : null,
        };
    }, [audioUrl, isPlaying, thumbnail, audio, isLoop, queryParams]);

    const miniPlayerClick = () => {
        const options = miniplayerOptions.current;
        if (options)
            onClickMiniPlayer({
                isAudio: true,
                mediaUrl: options.mediaUrl,
                thumbnail: options.thumbnail,
                title: options.title,
                content: options.content,
                singleLoop: options.singleLoop,
                parentLink: options.parentLink,
                recommendation: options.recommendation,
                contentType: options.contentType,
            })();
    };

    // this useEffect is for component unmounting
    useEffect(() => {
        return () => {
            if (
                miniplayerOptions.current &&
                miniplayerOptions.current.isPlaying
            )
                miniPlayerClick();
        };
    }, []);

    const playerEnded = () => {
        handleContentCompleted();
        if (isReceivedMemberAudio) logGiftCompleted();
    };

    const hasGiftShortcut = useMemo(() => {
        return (
            !!audio &&
            "member_gift_sku" in audio &&
            (!!audio.member_gift_sku || !!audio.prospect_gift_sku)
        );
    }, [audio]);

    return (
        <Layout
            onBuyClick={onBuyConfirm}
            method={paymentMethod}
            isPurchasing={purchaseEnabled && !dialogs.transactionComplete}
            purchaseNavText={purchaseText}
            purchaseEnabled={purchaseEnabled}
            activeGiftItem={activeGiftItem}
            availableCredits={availableCredits}
            isBuyDisabled={
                purchaseEnabled && activeGiftItem?.giftPurchasedEnabled
                    ? activeGiftItem.quantity < 1 || isRedeemDisabled
                    : false
            }
            dialogs={dialogs}
        >
            <AppHeader
                title={audio?.title}
                canGoBack
                searchable={false}
                onBackClick={onBackClick}
            />

            <Grid container className={clsx(classes.root, className)} {...rest}>
                {isContentLoaded && (
                    <>
                        <>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Typography
                                    className={classes.greyA100}
                                    fontWeight={"bold"}
                                    variant="h6"
                                >
                                    {audio.title}
                                </Typography>
                                <div className={classes.warpperFavAndDownload}>
                                    <IconButton>
                                        {favIconLoading ? (
                                            <AppCircularProgress loaderSize="small" />
                                        ) : (
                                            <FontAwesomeIcon
                                                onClick={handleFavouriteClick}
                                                style={{ fontSize: "22px" }}
                                                icon={[
                                                    isFavoriteAudio
                                                        ? "fas"
                                                        : "far",
                                                    "star",
                                                ]}
                                                className={classes.icon}
                                            />
                                        )}
                                    </IconButton>

                                    {isMediaPlayable &&
                                        !isAudioDownloaded &&
                                        !addedToDownloadQueue &&
                                        !isInDownloadQueue(audio?.nid) &&
                                        !isDownloading && (
                                            <IconButton>
                                                {downloadStatus ===
                                                DownloadStatus.BLOCK ? (
                                                    <DownloadDisabled />
                                                ) : (
                                                    <DownloadWarningDialog
                                                        onConfirm={handleDownload()}
                                                    >
                                                        {(renderProps) => (
                                                            <AppDownloadingIcons
                                                                onClick={
                                                                    renderProps.askConfirmation
                                                                }
                                                                iconType="download"
                                                            />
                                                        )}
                                                    </DownloadWarningDialog>
                                                )}
                                            </IconButton>
                                        )}

                                    {isAudioDownloaded && (
                                        <IconButton>
                                            <AppDownloadingIcons
                                                onClick={
                                                    handleToggleConfirmDialog
                                                }
                                                iconType="trash"
                                            />
                                        </IconButton>
                                    )}

                                    {!isAudioDownloaded &&
                                        !isDownloading &&
                                        (isInDownloadQueue(audio?.nid) ||
                                            addedToDownloadQueue) &&
                                        !inProgressNid?.includes(
                                            audio?.nid,
                                        ) && (
                                            <IconButton>
                                                <AppDownloadingIcons
                                                    onClick={
                                                        handleNavigateToQueue
                                                    }
                                                    iconType="queued"
                                                />
                                            </IconButton>
                                        )}
                                    {isDownloading && (
                                        <IconButton>
                                            <AppDownloadingIcons
                                                onClick={handleNavigateToQueue}
                                                iconType="in-progress"
                                            />
                                        </IconButton>
                                    )}
                                    {isOpenConfirmDailog && (
                                        <AppConfimationDialog
                                            open={isOpenConfirmDailog}
                                            onConfirm={() =>
                                                handleDelete(audio.nid)
                                            }
                                            btnConfirmLoader={deleteLoader}
                                            onClose={handleToggleConfirmDialog}
                                            popupMainText={`Are you sure you want to delete ${
                                                isBundle
                                                    ? "Audio Bundle?"
                                                    : "Audio?"
                                            }`}
                                            confirmText="Delete"
                                            cancelButtonText="Cancel"
                                            showConfirmFirst={true}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid
                                item
                                className={classes.grey500}
                                xs={12}
                                display="flex"
                            >
                                {!audio.isReceived &&
                                    !audio.is_purchased &&
                                    (audio.is_redeemable_by_tokens === "1" ||
                                        audio?.publish_free === "0" ||
                                        audio?.publish_free === "false") && (
                                        <>
                                            {audio.is_redeemable_by_tokens ===
                                                "1" && (
                                                <>
                                                    <Typography
                                                        className={
                                                            classes.constMargin
                                                        }
                                                        fontWeight={"bold"}
                                                        variant="body1"
                                                    >
                                                        {`${
                                                            audio.number_of_tokens
                                                        } Credit${
                                                            +audio?.number_of_tokens >
                                                            1
                                                                ? "s"
                                                                : ""
                                                        }`}
                                                    </Typography>
                                                    {(audio.publish_free ===
                                                        "0" ||
                                                        audio.publish_free ===
                                                            "false") && (
                                                        <Typography variant="body1">
                                                            |&nbsp;&nbsp;
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                            {+audio.content_price > 0 &&
                                                (audio.publish_free === "0" ||
                                                    audio.publish_free ===
                                                        "false") && (
                                                    <Typography
                                                        className={
                                                            classes.constMargin
                                                        }
                                                        fontWeight={"bold"}
                                                        variant="body1"
                                                    >
                                                        {`$${(+audio.content_price).toFixed(
                                                            2,
                                                        )}`}
                                                    </Typography>
                                                )}
                                        </>
                                    )}
                                <Typography variant="body1">
                                    {audio.sku_id}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.playerContainer}
                            >
                                {isMediaPlayable &&
                                mediaURL?.length > 0 &&
                                !!thumbnail &&
                                !isBundle ? (
                                    <MediaPlayer
                                        title={audio?.title}
                                        mediaURL={audioUrl}
                                        imageURL={thumbnail}
                                        onStart={handleAudioStartedPlaying}
                                        isAudio={true}
                                        playerRef={playerRef}
                                        progressInterval={
                                            PLAYER_PROGRESS_INTERVAL
                                        }
                                        loop={isLoop}
                                        onReady={() => onMediaPlayerReady()}
                                        onProgress={onPlayerProgress}
                                        onEnded={playerEnded}
                                        onPlay={() => {
                                            setIsPlaying(true);
                                        }}
                                        onPause={() => {
                                            setIsPlaying(false);
                                        }}
                                        onError={(e) => setIsReadyToPlay(true)}
                                        onUpdateExpiredUrl={() => {
                                            setIsReady(false);
                                            setIsReadyToPlay(true);
                                        }}
                                        playing={
                                            ((!isPurchasedNow &&
                                                isAutoPlay === "true") ||
                                                isPlaying) &&
                                            !miniPlayer.url
                                        }
                                        loading={!isReadyToPlay}
                                    />
                                ) : !!thumbnail ? (
                                    <img
                                        alt={audio?.title}
                                        src={thumbnail}
                                        className={classes.mediaImage}
                                    />
                                ) : (
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                        className={classes.dummyImageBox}
                                    >
                                        <span
                                            className={classes.graphicWrapper}
                                        >
                                            <GraphicEqIcon
                                                className={classes.graphicIcon}
                                                sx={{
                                                    fontSize: "60px",
                                                }}
                                            />
                                        </span>
                                    </Box>
                                )}
                            </Grid>
                        </>
                        {audio && !purchaseEnabled && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box className={classes.controlIcons}>
                                        {audio &&
                                        (audio.isReceived ||
                                            audio?.is_purchased ||
                                            audio?.publish_free === "1" ||
                                            audio?.publish_free === "true") ? (
                                            <>
                                                <IconButton
                                                    onClick={
                                                        handlePlaylistClick
                                                    }
                                                    className={
                                                        classes.iconPadding
                                                    }
                                                >
                                                    <PlaylistAddIcon
                                                        sx={{
                                                            fontSize: "30px",
                                                        }}
                                                        className={
                                                            classes.addToPlaylistIcon
                                                        }
                                                    />
                                                </IconButton>
                                                <ListDialog
                                                    open={openPlaylistSelection}
                                                    items={sortPlaylists(
                                                        playlistsInStore,
                                                    ).map((p) => {
                                                        return {
                                                            id: p.playlist_id,
                                                            title: p.playlist_name,
                                                        };
                                                    })}
                                                    onSelection={
                                                        handlePlaylistSelected
                                                    }
                                                    onClose={() =>
                                                        setAnchorPlaylist(null)
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <AppButton
                                                onClick={() =>
                                                    setPurchaseEnabled(true)
                                                }
                                                buttonVariant="grey-outlined"
                                                rounded
                                            >
                                                BUY
                                            </AppButton>
                                        )}
                                    </Box>

                                    {isBundle && isMediaPlayable && (
                                        <>
                                            <AppButton
                                                onClick={() => playClick()}
                                                buttonVariant="grey-outlined"
                                                rounded
                                            >
                                                PLAY
                                            </AppButton>
                                        </>
                                    )}

                                    {!isBundle && isMediaPlayable && (
                                        <PlayerActionBtns
                                            handleChangeSeek={handleChangeSeek}
                                            isLoop={isLoop}
                                            onClickLoop={() => setLoop(!isLoop)}
                                            onClickMiniPlayer={miniPlayerClick}
                                        />
                                    )}

                                    <RenderIf
                                        isTrue={
                                            audio &&
                                            !audio.isReceived &&
                                            hasAttachSku(audio) &&
                                            hasGiftShortcut
                                        }
                                        fallback={
                                            miniPlayer.url ? (
                                                <></>
                                            ) : (
                                                <Box
                                                    display="flex"
                                                    width={"25px"}
                                                ></Box>
                                            )
                                        }
                                    >
                                        <Box className={classes.giftIcons}>
                                            {hasGiftShortcut && (
                                                <Grid
                                                    className={
                                                        classes.giftSectionWarpper
                                                    }
                                                >
                                                    {(loadingGiftMemeberQuanity ||
                                                        loadingGiftProspectQuanity ||
                                                        audioGiftsLoading) && (
                                                        <AppCircularProgress
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        />
                                                    )}

                                                    {!loadingGiftMemeberQuanity &&
                                                        !loadingGiftProspectQuanity &&
                                                        !audioGiftsLoading &&
                                                        (giftMemberQuantity >
                                                            0 ||
                                                            giftProspectQuantity >
                                                                0) && (
                                                            <Box>
                                                                <IconButton
                                                                    onClick={(
                                                                        e,
                                                                    ) =>
                                                                        setAnchorSendGift(
                                                                            e.currentTarget,
                                                                        )
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        className={
                                                                            classes.icon
                                                                        }
                                                                        icon={[
                                                                            "far",
                                                                            "paper-plane",
                                                                        ]}
                                                                    />
                                                                </IconButton>
                                                                {"member_gift_sku" in
                                                                    audio && (
                                                                    <AppMenuListing
                                                                        id="gift-type-send-audios"
                                                                        anchorEl={
                                                                            anchorSendGift
                                                                        }
                                                                        open={
                                                                            openSendGift
                                                                        }
                                                                        onClose={() =>
                                                                            setAnchorSendGift(
                                                                                null,
                                                                            )
                                                                        }
                                                                    >
                                                                        <AttachSkuMenu
                                                                            skus={convertToAttachSkuArray(
                                                                                audio.member_gift_sku,
                                                                                giftMemberQuantity,
                                                                                !audio.member_gift_sku &&
                                                                                    audio.prospect_gift_sku,
                                                                                giftProspectQuantity,
                                                                            )}
                                                                            loaded={
                                                                                !loadingGiftMemeberQuanity &&
                                                                                !loadingGiftProspectQuanity &&
                                                                                !audioGiftsLoading
                                                                            }
                                                                            onItemClick={
                                                                                onGiftSendMenuItem
                                                                            }
                                                                        />
                                                                    </AppMenuListing>
                                                                )}
                                                            </Box>
                                                        )}
                                                    <IconButton
                                                        className={
                                                            (classes.iconPadding,
                                                            (loadingGiftMemeberQuanity ||
                                                                loadingGiftProspectQuanity ||
                                                                audioGiftsLoading) &&
                                                                classes.giftIconButtonDisabled)
                                                        }
                                                        onClick={(e) => {
                                                            if (
                                                                loadingGiftMemeberQuanity ||
                                                                loadingGiftProspectQuanity ||
                                                                audioGiftsLoading
                                                            ) {
                                                                enqueueSnackbar(
                                                                    messaging
                                                                        ?.gifts
                                                                        ?.fetchingGiftInfo,
                                                                    {
                                                                        variant:
                                                                            "warning",
                                                                    },
                                                                );
                                                            } else {
                                                                setAnchorEl(
                                                                    e.currentTarget,
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className={
                                                                classes.icon
                                                            }
                                                            icon={[
                                                                "fas",
                                                                "gift",
                                                            ]}
                                                        />
                                                    </IconButton>
                                                    {"member_gift_sku" in
                                                        audio && (
                                                        <AppMenuListing
                                                            id="gift-type-menu-audios"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={() =>
                                                                setAnchorEl(
                                                                    null,
                                                                )
                                                            }
                                                        >
                                                            <AttachSkuMenu
                                                                skus={convertToAttachSkuArray(
                                                                    audio.member_gift_sku,
                                                                    giftMemberQuantity,
                                                                    audio.prospect_gift_sku,
                                                                    giftProspectQuantity,
                                                                )}
                                                                loaded={
                                                                    !loadingGiftMemeberQuanity &&
                                                                    !audioGiftsLoading
                                                                }
                                                                onItemClick={
                                                                    onGiftMenuItemClick
                                                                }
                                                            />
                                                        </AppMenuListing>
                                                    )}
                                                </Grid>
                                            )}
                                        </Box>
                                    </RenderIf>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={clsx(
                                            classes.grey500,
                                            classes.date,
                                        )}
                                        variant="body2"
                                    >
                                        {moment
                                            .unix(+audio.release_date)
                                            .format("LL")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.grey800}
                                        variant="body2"
                                    >
                                        {audio.description}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {purchaseEnabled && !dialogs?.selectMembers && (
                            <ContentPurchase
                                marginBottom={"30px"}
                                onBackClick={() => {
                                    setPurchaseEnabled(false);
                                    setActiveGiftItem({
                                        title: GiftsTypes.DEFAULT,
                                        giftPurchasedEnabled: false,
                                        quantity: 1,
                                        giftPurchasedSuccess: false,
                                    });
                                }}
                                buying={
                                    purchasingContent ||
                                    redeemingContent ||
                                    redeemingGiftContent
                                }
                                cart={cart}
                                backTitle={
                                    activeGiftItem?.giftPurchasedEnabled
                                        ? activeGiftItem?.title
                                        : "Audio Purchase"
                                }
                                credits={availableCredits}
                                availableCreditsLoading={
                                    availableCreditsLoading
                                }
                                quantityLoading={
                                    loadingGiftMemeberQuanity ||
                                    loadingGiftProspectQuanity
                                }
                                requiredCredits={
                                    activeGiftItem?.giftPurchasedEnabled
                                        ? giftItem?.number_of_tokens
                                        : audio?.number_of_tokens
                                }
                                method={paymentMethod}
                                activeGiftItem={activeGiftItem}
                                onSendContent={onSendContent}
                                selectedMembers={selectedMembers}
                                totalPrice={`$${parseFloat(
                                    `${
                                        activeGiftItem.giftPurchasedEnabled
                                            ? giftItem.content_price
                                            : audio?.content_price
                                    }`,
                                ).toFixed(2)}`}
                                contentPrice={
                                    activeGiftItem?.giftPurchasedEnabled
                                        ? giftItem?.content_price
                                        : `${audio?.content_price}`
                                }
                                onMethodChange={(method) => {
                                    if (method === PaymentMethod.MY_CREDITS) {
                                        if (
                                            (activeGiftItem.giftPurchasedEnabled &&
                                                giftItem.is_redeemable_by_tokens ===
                                                    "1") ||
                                            (!activeGiftItem.giftPurchasedEnabled &&
                                                audio.is_redeemable_by_tokens)
                                        ) {
                                            setPaymentMethod(method);
                                        } else {
                                            enqueueSnackbar(
                                                messaging?.redeeming
                                                    ?.notRedeemable,
                                                { variant: "warning" },
                                            );
                                        }
                                    }

                                    if (method === PaymentMethod.CARD) {
                                        setPaymentMethod(method);
                                    }
                                }}
                                dialogs={dialogs}
                                setDialogs={setDialogs}
                                onBuy={onBuyContent}
                                onBuyGift={onBuyGiftContent}
                                isRedemeableByToken={`${
                                    activeGiftItem.giftPurchasedEnabled
                                        ? giftItem.is_redeemable_by_tokens
                                        : audio.is_redeemable_by_tokens
                                }`}
                                onCloseConfirmDialog={onCloseSelectMembers}
                            />
                        )}
                        {purchaseEnabled &&
                            activeGiftItem?.giftPurchasedEnabled &&
                            !dialogs?.selectMembers && (
                                <GiftQuantity
                                    activeGiftItem={activeGiftItem}
                                    onQuantityChange={onQuantityChange}
                                    method={paymentMethod}
                                    credits={availableCredits}
                                    isInsufficeintCredits={
                                        isInsufficeintCredits
                                    }
                                />
                            )}

                        {purchaseEnabled &&
                            activeGiftItem?.giftPurchasedEnabled &&
                            dialogs?.selectMembers && (
                                <SelectMembersDialog
                                    open={true}
                                    searchable={true}
                                    searchPlaceholder="Search by LOS or LTD ID"
                                    searchQuery={searchQueryLos}
                                    setSearchQuery={setSearchQueryLos}
                                    selectedMembers={selectedMembers}
                                    checkboxList={searchLosResults}
                                    onToggleItemSelect={onToggleItemSelect}
                                    onClose={onCloseSelectMembers}
                                    onSend={onSendSelectedMembers}
                                    giftSendLoading={sendingGifts}
                                    availableQuantity={
                                        activeGiftItem?.quantity -
                                            selectedMembers?.length >
                                        0
                                            ? activeGiftItem?.quantity -
                                              selectedMembers?.length
                                            : 0
                                    }
                                />
                            )}
                        {queueLimitReached && (
                            <AppConfimationDialog
                                titleText={
                                    messaging?.downloadingQueue?.QueueLimitation
                                }
                                showTitle={true}
                                open={queueLimitReached}
                                popupMainText={
                                    messaging?.downloadingQueue?.limitReached
                                }
                                cancelButtonText="OK"
                                onClose={toggleQueueLimitReached}
                            />
                        )}
                    </>
                )}

                {(!isContentLoaded || detailLoading) && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.audio}
                    />
                )}

                {favIconLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.processing}
                    />
                )}

                {playlistsLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.playlist}
                    />
                )}
                {addToPlaylistLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.add_to_playlist}
                    />
                )}
                {isCreatePlaylist && (
                    <InputDialog
                        open={true}
                        title={"Create Playlist"}
                        hint={"Please enter playlist name."}
                        cancelText={"Cancel"}
                        okText={"Create"}
                        cancelClicked={() => setCreatePlaylist(false)}
                        okClicked={createClick}
                    />
                )}
                {createLoading && (
                    <AppBackdropProgress
                        open={true}
                        backdropText={messaging.loader.create_playlist}
                    />
                )}
            </Grid>
        </Layout>
    );
};

const useRecommendationAnalytics = () => {
    const queryParams = useQueryParams();
    const { recId, source, displayCount } = queryParams;
    const { logFirebaseEvent } = useFirebaseLogger();

    const getCommonEventActions = (
        audio: Content,
    ): Partial<FirebaseEventAction> => {
        return {
            category: EventCategories.RECOMMENDATIONS,
            recId: `${recId}`,
            nId: `${audio.nid}`,
            skuId: audio.sku_id,
            contentTitle: audio.title,
            displayCount: displayCount,
            source: source,
        };
    };

    const recPlayerAnalytics = (
        type: "played" | "live-stream",
        audio: Content,
    ) => {
        if (audio) {
            const commonEventActions = getCommonEventActions(audio);
            const actionAndEvent = recPlayerAnalyticsActionAndEvent(type);

            logFirebaseEvent(actionAndEvent.name, {
                ...commonEventActions,
                action: actionAndEvent.action,
            });
        }
    };

    return {
        queryParams,
        recPlayerAnalytics,
    };
};

const useQueryParams = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const {
            recId = null,
            source = "",
            displayCount = null,
            isMixedContent = false,
            scrollTo = "0",
        } = parsedQueryString;

        const src = source ? source.toString() : "";

        return {
            recId: recId ? +recId : null,
            source: src as RecommendationSource,
            displayCount: displayCount ? +displayCount : null,
            isMixedContent: !!isMixedContent ? true : false,
            scrollTo: scrollTo,
        };
    }, [location?.search]);

    return queryParams;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        spinner: {
            color: theme.palette.primary.main,
        },
        icon: {
            color: theme.palette.grey[600],
            fontSize: "22px",
        },
        constMargin: {
            marginRight: theme.spacing(2),
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },
        graphicIcon: {
            color: theme.palette.grey[400],
        },
        graphicWrapper: {
            border: `5px solid ${theme.palette.common.white}`,
            borderRadius: "50%",
            height: "120px",
            width: "120px",
            marginRight: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        dummyImageBox: {
            background: theme.palette.grey[900],
            height: "100%",
        },
        mediaImage: {
            width: "100%",
            height: "100%",
            objectFit: "contain",
            background: theme.palette.common.black,
        },
        date: {
            marginTop: theme.spacing(2),

            marginBottom: theme.spacing(1),
        },
        transactionCompletePaper: {
            color: theme.palette.grey[600],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "120px",
        },
        transactionConfirmation: {
            color: theme.palette.grey[600],
            minHeight: "120px",
        },
        giftQuanityWrapper: {
            background: `${theme.palette.grey[300]} !important`,
            color: theme.palette.grey[600],
            borderRadius: "50%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            padding: theme.spacing(0.5),
            minWidth: "25px",
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
        },
        giftSectionWarpper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
        giftIconButtonDisabled: {
            opacity: "50%",
            cursor: "not-allowed !important",
        },
        warpperFavAndDownload: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        controlIcons: {
            maxWidth: "40px",
            display: "flex",
        },
        giftIcons: {
            display: "flex",
        },
        iconPadding: {
            padding: "8px 0",
        },
        playerIcon: {
            height: "35px",
        },
        addToPlaylistIcon: {
            color: theme.palette.grey[600],
        },
        addToPlaylistDialog: {
            backgroundColor: theme.palette.background.default,
        },
        listingStyle: {
            [theme.breakpoints.down("sm")]: {
                mt: "1px",
                "& .MuiMenu-paper": {
                    backgroundColor: "black",
                },
            },
        },
    }),
);

export interface AudioContentDetailProps
    extends InternalStandardProps<GridProps> {}
