import clsx from "clsx";
import { Box, BoxProps, InternalStandardProps, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useMemo } from "react";
import {
    useGlobalBlackRecommendations,
    useGlobalRecommendations,
    useOpenAutoPopup,
    useRecommendations,
} from "JS/React/Hooks/Recommendations";
import { AppBackdropProgress } from "JS/React/Components/Progress/AppBackdropProgress";
import { useOpenConsentForm } from "../Hooks/ConsentForm";
export interface RecommendationsManagerProps
    extends InternalStandardProps<BoxProps> {
    children: React.ReactNode;
}

export const RecommendationsManager = (props: RecommendationsManagerProps) => {
    const classes = useStyles(props);

    const { className, children, ...rest } = props;

    const { recommendationsLoading, open } = useRecommendationVariables();
    useFirstStart(!open);

    const loading = recommendationsLoading;

    return (
        <Box className={clsx(className, classes.root)} {...rest}>
            {loading ? (
                <AppBackdropProgress
                    open
                    backdropText="Loading Recommendations"
                />
            ) : (
                <>{children}</>
            )}
        </Box>
    );
};

export const useRecommendationVariables = () => {
    const { recommendationState } = useGlobalRecommendations();

    const skipRecommendations = recommendationState?.recommendations != null;

    const { loading: recommendationsLoading, loaded: recommendationsLoaded } =
        useRecommendations(true);

    const open = useMemo(() => {
        return skipRecommendations || recommendationsLoaded;
    }, [skipRecommendations, recommendationsLoaded]);
    return {
        recommendationsLoading,
        open,
    };
};

const useFirstStart = (skip: boolean = false) => {
    const { blackRecommendationState, setGlobalBlackRecommendations } =
        useGlobalBlackRecommendations();
    const { openPopup } = useOpenAutoPopup();
    const { openForm } = useOpenConsentForm();

    useEffect(() => {
        if (blackRecommendationState?.firstStart) {
            /**Anything here will run on application restart */
            setGlobalBlackRecommendations({ firstStart: false });
            if (!skip) openPopup();
            openForm();
        }
    }, [blackRecommendationState?.firstStart, skip]);
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
    }),
);
