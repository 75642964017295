import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Grid,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import {
    VideoDexie,
    AudioDexie,
    getDexieConnectionRefresh,
} from "JS/Database/Dexie";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { useState, useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AppConfimationDialog } from "JS/React/Components/AppConfirmationDialog";
import { AppHeader } from "JS/React/Components/AppHeader";
import { useSnackbar } from "notistack";
import { messaging } from "JS/Helpers/UserMessaging";
import FallbackWaveform from "Images/Content/audio-waves.png";
import {
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
} from "JS/Models";
import moment from "moment";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
} from "JS/React/Hooks/Gifts";
import { getDownloadedPartsNids } from "JS/Helpers";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import { AppButton } from "JS/React/Components/AppButton";
import { useRouting } from "JS/React/Hooks/Routes";
import {
    useContentPlayingHandlers,
    usePlayerHandlers,
} from "JS/React/Hooks/MediaPlayer";
import { PlayerActionBtns } from "JS/React/Components/MediaPlayer/PlayerActionBtns";
import { useResumeRecentlyOfflineContent } from "JS/React/Hooks/ResumeRecently";
import { PLAYER_PROGRESS_INTERVAL } from "JS/Helpers/Contants";
import { AppDownloadingIcons } from "JS/React/Components/Icon/AppDownloadingIcons";
import {
    SentGiftType,
    getPlayerPlayedTimeToLog,
    toSentOfflineGift,
} from "JS/Models/Firebase/GiftDashboard";
import qs from "qs";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import { useGlobalAudioDetails } from "JS/React/Hooks/Audio";

export interface ContentDetailOfflineProps
    extends InternalStandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >
    > {}

export const ContentDetailOffline = (props: ContentDetailOfflineProps) => {
    const classes = useStyles(props);
    const { className, ...rest } = props;
    const [content, setContent] = useState<VideoDexie | AudioDexie>();
    const params: any = useParams();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const { logFirebaseEvent } = useFirebaseLogger();

    const [isOpenConfirmDailog, setIsOpenConfirmDailog] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const { linkProvider } = useRouting();

    const {
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
    } = useGlobalGifts();
    const { scrollTo } = useQueryParams();
    const { nid, type, isAutoPlay } = params;
    const [currentPlayingState, setCurrentPlayingState] = useState(
        isAutoPlay === "true",
    );
    const { handlePlayerPause, handlePlayerPlay } = useContentPlayingHandlers(
        setCurrentPlayingState,
    );

    const contentNid = nid.split("-")[1] ? nid.split("-")[1] : "0";

    const { description } = useDescription(type, contentNid);

    const isGift =
        type === "audio"
            ? receivedMemberAudioGifts.find((r) => r.nid === contentNid) !==
                  undefined ||
              receivedProspectAudioGifts.find((r) => r.nid === contentNid) !==
                  undefined
            : receivedMemberVideoGifts.find((r) => r.nid === contentNid) !==
                  undefined ||
              receivedProspectVideoGifts.find((r) => r.nid === contentNid) !==
                  undefined;

    const currentGift: Gifts = useMemo(() => {
        if (type === "audio") {
            return (
                receivedMemberAudioGifts.find((r) => r.nid === contentNid) ||
                receivedProspectAudioGifts.find((r) => r.nid === contentNid)
            );
        } else {
            return (
                receivedMemberVideoGifts.find((r) => r.nid === contentNid) ||
                receivedProspectVideoGifts.find((r) => r.nid === contentNid)
            );
        }
    }, [
        receivedMemberAudioGifts,
        receivedProspectAudioGifts,
        receivedMemberVideoGifts,
        receivedProspectVideoGifts,
        type,
    ]);

    const {
        playerRef,
        handleContentCompleted,
        onMediaPlayerReady,
        onPlayerProgress,
        setIsReady,
        isReadyToPlay,
        setIsReadyToPlay,
    } = useResumeRecentlyOfflineContent(content, type);

    const { handleChangeSeek } = usePlayerHandlers(playerRef);

    const getDashboardActionDetails = () => {
        return getPlayerPlayedTimeToLog(playerRef);
    };

    const giftDashboardData = useMemo(() => {
        return toSentOfflineGift(content);
    }, [content]);

    const { isGift: isAudioGift } = useReceivedMemberAudioGifts(true);
    const { isGift: isVideoGift } = useReceivedMemberVideoGifts(true);
    const isReceivedMemberGift =
        isAudioGift(contentNid) || isVideoGift(contentNid);

    const { logGiftOpen, logGiftCompleted } = useGiftDashboard(
        giftDashboardData,
        type === "audio" ? SentGiftType.AUDIO : SentGiftType.VIDEO,
        currentGift?.sender?.member_id,
        isReceivedMemberGift && currentPlayingState,
        getDashboardActionDetails,
        true,
    );

    const playerEnded = () => {
        handleContentCompleted();
        if (isReceivedMemberGift) logGiftCompleted();
    };

    useEffect(() => {
        (async () => {
            const db = getDexieConnectionRefresh();
            const activeDb = type === "audio" ? db.audios : db.videos;

            const response = await activeDb.get(nid);

            setContent({
                ...response,
                description: description || response.description,
            });
        })();
    }, [nid, type, description]);

    useEffect(() => {
        if (content) {
            let category = EventCategories.VIDEOS;
            let name = EventNames.VIDEO_OPENED;
            if (isGift) {
                category = EventCategories.GIFT_VIDEOS;
                name = EventNames.GIFT_VIDEO_OPENED;
            }
            if (type === "audio") {
                category = EventCategories.AUDIOS;
                name = EventNames.AUDIO_OPENED;
                if (isGift) {
                    category = EventCategories.GIFT_AUDIOS;
                    name = EventNames.GIFT_AUDIO_OPENED;
                }
            }
            const action: FirebaseEventAction = {
                action: EventActions.OPEN,
                contentTitle: content.name,
                nId: contentNid,
                skuId: content.skuId,
                category: category,
            };
            if (isReceivedMemberGift) logGiftOpen();
            logFirebaseEvent(name, action);
        }
    }, [type, content, nid, isReceivedMemberGift]);

    const handleToggleConfirmDialog = () => {
        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const [isLoop, setLoop] = useState(false);

    const handleDelete = async (nid: string, type: string) => {
        if (type !== "audioBundle") {
            const db = getDexieConnectionRefresh();
            const activeDb = type === "audio" ? db.audios : db.videos;
            await activeDb
                .delete(nid)
                .then(() => {
                    enqueueSnackbar(messaging.download.deleteSuccess, {
                        variant: "success",
                    });
                    history.goBack();
                })
                .catch(() => {
                    enqueueSnackbar(messaging.download.deleteError, {
                        variant: "error",
                    });
                });
        } else {
            const id = nid.split("-")[1];
            let deleteSuccess = true;
            setDeleteLoader(true);
            await getDownloadedPartsNids(id).then(async (res) => {
                if (res.length) {
                    for (let i = 0; i < res.length; i++) {
                        await getDexieConnectionRefresh()
                            .audios.delete(res[i])
                            .then(() => {})
                            // eslint-disable-next-line no-loop-func
                            .catch(() => {
                                deleteSuccess = false;
                            });
                        if (i === res.length - 1) {
                            if (deleteSuccess) {
                                enqueueSnackbar(
                                    messaging.download.deleteSuccess,
                                    {
                                        variant: "success",
                                    },
                                );
                                history.goBack();
                            } else {
                                enqueueSnackbar(
                                    messaging.download.deleteError,
                                    {
                                        variant: "error",
                                    },
                                );
                            }
                            setDeleteLoader(false);
                        }
                    }
                }
            });
        }

        setIsOpenConfirmDailog(!isOpenConfirmDailog);
    };

    const handleAudioStartedPlaying = () => {
        if (content) {
            let category = EventCategories.VIDEOS;
            let name = EventNames.VIDEO_PLAYED;
            if (isGift) {
                category = EventCategories.GIFT_VIDEOS;
                name = EventNames.GIFT_VIDEO_PLAYED;
            }
            if (type === "audio") {
                category = EventCategories.AUDIOS;
                name = EventNames.AUDIO_PLAYED;
                if (isGift) {
                    category = EventCategories.GIFT_AUDIOS;
                    name = EventNames.GIFT_AUDIO_PLAYED;
                }
            }

            const action: FirebaseEventAction = {
                action: EventActions.PLAY,
                contentTitle: content.name,
                nId: contentNid,
                skuId: content.skuId,
                category: category,
            };
            logFirebaseEvent(name, action);
        }
    };

    const playClick = () => {
        history.push(linkProvider.react.offline().bundle().audios(content?.id));
    };

    const mediaURL = useMemo(() => {
        return content?.blob && content?.isBundle !== true
            ? URL.createObjectURL(content?.blob)
            : "";
    }, [content?.id]);

    return (
        <div {...rest} className={clsx(className, classes.root)}>
            <AppHeader
                title={content?.name}
                canGoBack={true}
                searchable={false}
                showTabs={false}
                onBackClick={() => {
                    history.push(
                        linkProvider.react
                            .offline()
                            .listing(type === "audio" ? "Audios" : "Videos", {
                                scrollTo: scrollTo ? +scrollTo : 0,
                            }),
                    );
                }}
            />
            <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                className={clsx(classes.bodyPadding)}
            >
                <Typography
                    className={classes.greyA100}
                    fontWeight={"bold"}
                    variant="h6"
                >
                    {content?.name}
                </Typography>
                <div>
                    <IconButton>
                        <AppDownloadingIcons
                            iconType="trash"
                            onClick={handleToggleConfirmDialog}
                        />
                    </IconButton>

                    {isOpenConfirmDailog && (
                        <AppConfimationDialog
                            open={isOpenConfirmDailog}
                            onConfirm={() =>
                                handleDelete(
                                    nid,
                                    content?.isBundle ? "audioBundle" : type,
                                )
                            }
                            btnConfirmLoader={deleteLoader}
                            onClose={handleToggleConfirmDialog}
                            popupMainText={`Are you sure you want to delete ${
                                content?.isBundle ? "Audio Bundle" : type
                            }?`}
                            confirmText="Delete"
                            cancelButtonText="Cancel"
                            showConfirmFirst={true}
                        />
                    )}
                </div>
            </Grid>
            <Grid
                item
                className={clsx(classes.grey500, classes.bodyPadding)}
                xs={12}
                display="flex"
            >
                <Typography variant="body1">{content?.skuId}</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                className={clsx(classes.playerContainer, classes.bodyPadding)}
            >
                <MediaPlayer
                    title={content?.name}
                    mediaURL={mediaURL}
                    imageURL={FallbackWaveform}
                    playerRef={playerRef}
                    progressInterval={PLAYER_PROGRESS_INTERVAL}
                    loop={isLoop}
                    onStart={handleAudioStartedPlaying}
                    onReady={onMediaPlayerReady}
                    onProgress={onPlayerProgress}
                    onEnded={playerEnded}
                    isAudio={type === "audio"}
                    playing={
                        type === "audio"
                            ? isAutoPlay === "true" || currentPlayingState
                            : isAutoPlay === "true" && currentPlayingState
                    }
                    onPlay={handlePlayerPlay}
                    onPause={handlePlayerPause}
                    loading={!isReadyToPlay && !content?.isBundle}
                    onUpdateExpiredUrl={() => {
                        setIsReady(false);
                        setIsReadyToPlay(true);
                    }}
                    onError={() => {
                        setIsReadyToPlay(true);
                    }}
                />
            </Grid>

            {!content?.isBundle && (
                <Grid
                    item
                    xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    className={clsx(classes.bodyPadding)}
                >
                    <PlayerActionBtns
                        isLoop={isLoop}
                        handleChangeSeek={handleChangeSeek}
                        onClickLoop={() => setLoop(!isLoop)}
                    />
                </Grid>
            )}
            {content?.isBundle && (
                <div
                    className={clsx(
                        classes.playButtonWrapper,
                        classes.bodyPadding,
                    )}
                >
                    <AppButton
                        onClick={() => playClick()}
                        buttonVariant="grey-outlined"
                        rounded
                    >
                        PLAY
                    </AppButton>
                </div>
            )}
            <Grid item xs={12} className={clsx(classes.bodyPadding)}>
                <Typography
                    className={clsx(classes.grey500, classes.date)}
                    variant="body2"
                >
                    {moment.unix(+content?.release_date).format("LL")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    className={clsx(classes.grey800, classes.bodyPadding)}
                    variant="body2"
                >
                    {content?.description}
                </Typography>
            </Grid>
        </div>
    );
};
const useQueryParams = () => {
    const location = useLocation();

    const queryParams = useMemo(() => {
        const parsedQueryString = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        const { scrollTo = "0" } = parsedQueryString;

        return {
            scrollTo: scrollTo,
        };
    }, [location?.search]);

    return queryParams;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        grey500: {
            color: theme.palette.grey[500],
        },
        grey800: {
            color: theme.palette.grey[800],
        },
        greyA100: {
            color: theme.palette.grey.A100,
        },
        date: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        playButtonWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        playerContainer: {
            margin: theme.spacing(2, 0),
            height: "60vh",
            [theme.breakpoints.down("lg")]: {
                height: "50vh",
            },
            [theme.breakpoints.down("sm")]: {
                height: "30vh",
            },
        },

        controlIcons: {
            minWidth: "40px",
            display: "flex",
        },
        playerIcon: {
            height: "35px",
        },
        bodyPadding: {
            paddingRight: "10px",
            paddingLeft: "10px",
        },
    }),
);

const useDescription = (type: string, nid: string) => {
    const { audioDetails } = useGlobalAudioDetails(nid);

    return {
        description: type === "audio" ? audioDetails?.description : undefined,
    };
};
