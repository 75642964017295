import {
    checkHoursPassed,
    checkIfBatchAllowsNotify,
    checkIfBatchIncludesIbos,
    isDeviceAllowed,
    processDownloadQueue,
    resetFirstElementInDownloadQueue,
} from "JS/Helpers";
import { useAuth } from "JS/React/Context/AuthenticationProvider";
import {
    useGlobalMediaEssentials,
    useMediaEssentials,
} from "JS/React/Hooks/MediaEssentials";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { useRouting } from "JS/React/Hooks/Routes";
import { useCallback, useEffect } from "react";
import { setIsRedirectedToAmmwayConsentForm } from "JS/Helpers/LocalStorageHelpers";
import { useGetLatestUsername } from "JS/React/Hooks/Users";
import { useResumeData } from "JS/React/Hooks/Firebase/Resume";
import { config } from "JS/Config";
import {
    useGlobalBlackRecommendations,
    useGlobalRecommendations,
    useOpenAutoPopup,
    useRecommendations,
    useRecommendationsBatch,
} from "JS/React/Hooks/Recommendations";
import { useOpenConsentForm } from "JS/React/Hooks/ConsentForm";
import { RecommendationsState } from "JS/Redux/Recommendations";
import { RecommendationBatch } from "JS/Models";

export const ForegroundTasks = () => {
    const {
        categoryId,
        linkProvider,
        history,
        allowRedirect,
        isAuthenticated,
        deviceAllowed,
    } = useRoutingVariables();
    const { fetchMediaEssentials, mediaEssentials } =
        useMediaEssentialVariables();
    const {
        fetchBatch,
        fetchRecommendations,
        recommendationState,
        refetchBatch,
        openPopup,
    } = useRecommendationVariables(categoryId);
    useMount(recommendationState, refetchBatch, fetchRecommendations);
    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { syncUsername } = useGetLatestUsername();
    const {
        logAudioResume,
        logVideoResume,
        logAudioBundleResume,
        logPlaylistResume,
    } = useResumeData();
    const { openForm } = useOpenConsentForm();

    const onVisibilityChange = useCallback(
        (e) => {
            if (
                document.visibilityState === "visible" &&
                deviceAllowed &&
                isAuthenticated
            ) {
                verifyAwsKeys().then(async () => {
                    const response = await fetchMediaEssentials();
                    if (!allowRedirect && response?.has_event_started)
                        history.push(linkProvider.react.webcast().index(true));
                    if (!mediaEssentials?.has_event_started && !allowRedirect) {
                        if (
                            recommendationState?.batch &&
                            checkHoursPassed(
                                recommendationState?.lastFetched,
                                HOURS_TO_CHECK,
                            )
                        )
                            await fetchBatch();
                        else openPopup(categoryId);
                        openForm();
                    }
                    resetFirstElementInDownloadQueue();
                    processDownloadQueue();
                    setIsRedirectedToAmmwayConsentForm("false");
                    syncUsername();
                    logAudioResume(memberId);
                    logVideoResume(memberId);
                    logAudioBundleResume(memberId);
                    logPlaylistResume(memberId);
                });
            }
        },
        [
            allowRedirect,
            deviceAllowed,
            fetchBatch,
            fetchMediaEssentials,
            history,
            isAuthenticated,
            linkProvider.react,
            logAudioBundleResume,
            logAudioResume,
            logPlaylistResume,
            logVideoResume,
            mediaEssentials?.has_event_started,
            openForm,
            openPopup,
            recommendationState?.batch,
            recommendationState?.lastFetched,
            syncUsername,
            verifyAwsKeys,
            categoryId,
        ],
    );

    useEffect(() => {
        document.addEventListener("visibilitychange", onVisibilityChange);

        return () =>
            document.removeEventListener(
                "visibilitychange",
                onVisibilityChange,
            );
    }, [onVisibilityChange]);

    return <></>;
};

const HOURS_TO_CHECK = 2;
const memberId = config.user.memberId;

const useFirstStart = (refetchBatch: () => void) => {
    const { blackRecommendationState } = useGlobalBlackRecommendations();
    const { recommendationState } = useGlobalRecommendations();

    useEffect(() => {
        if (blackRecommendationState?.firstStart) {
            /**Anything here will run on application restart */
            if (
                recommendationState?.batch &&
                checkHoursPassed(
                    recommendationState?.lastFetched,
                    HOURS_TO_CHECK,
                )
            )
                refetchBatch();
        }
    }, [blackRecommendationState?.firstStart]);
};

const useRoutingVariables = () => {
    const params: any = useParams();
    const { title, categoryId } = params;
    const { linkProvider } = useRouting();
    const history = useHistory();
    const location = useLocation();
    const allowRedirect =
        location.pathname === linkProvider.react.webcast().index() ||
        location.pathname === linkProvider.react.webcast().detail(title) ||
        location.pathname === linkProvider.react.webcast().stream(title);
    const { isAuthenticated } = useAuth();
    const deviceAllowed = isDeviceAllowed();

    return {
        categoryId,
        linkProvider,
        history,
        allowRedirect,
        isAuthenticated,
        deviceAllowed,
    };
};

const useMediaEssentialVariables = () => {
    const { refetch: fetchMediaEssentials } = useMediaEssentials(true);
    const { mediaEssentials } = useGlobalMediaEssentials();

    return {
        fetchMediaEssentials,
        mediaEssentials,
    };
};

const useRecommendationVariables = (categoryId: string) => {
    const { recommendationState, setGlobalRecommendations } =
        useGlobalRecommendations();
    const { refetch: refetchBatch } = useRecommendationsBatch();
    const { refetch: refetchRecommendations } = useRecommendations(true);
    const { openPopup } = useOpenAutoPopup();
    const fetchRecommendations = useCallback(
        () =>
            refetchRecommendations().then(() => {
                setGlobalRecommendations({
                    noOfPopups: 0,
                });

                openPopup(categoryId, true);
            }),
        [
            categoryId,
            openPopup,
            refetchRecommendations,
            setGlobalRecommendations,
        ],
    );
    const fetchBatch = useCallback(async () => {
        try {
            const apiBatch = await refetchBatch();
            const localBatch = recommendationState?.batch;

            if (localBatch) {
                const batchChanged = localBatch?.batchId !== apiBatch?.batchId;
                const includesIbo = checkIfBatchIncludesIbos(apiBatch);
                const allowsNotify = checkIfBatchAllowsNotify(apiBatch);

                if (batchChanged && includesIbo && allowsNotify)
                    await fetchRecommendations();
            }
        } catch {
            /*do nothing*/
        }
    }, [refetchBatch, recommendationState?.batch, fetchRecommendations]);

    useFirstStart(fetchBatch);

    return {
        fetchBatch,
        fetchRecommendations,
        recommendationState,
        refetchBatch,
        openPopup,
    };
};

const useMount = (
    recommendationState: RecommendationsState,
    refetchBatch: () => Promise<RecommendationBatch>,
    fetchRecommendations: () => Promise<void>,
) => {
    const onMount = async () => {
        const timePassed = recommendationState?.lastFetched
            ? checkHoursPassed(recommendationState?.lastFetched, HOURS_TO_CHECK)
            : true;

        if (!recommendationState.batch && timePassed) {
            const apiBatch = await refetchBatch();
            const includesIbo = checkIfBatchIncludesIbos(apiBatch);
            const allowsNotify = checkIfBatchAllowsNotify(apiBatch);

            if (
                !recommendationState.recommendations &&
                includesIbo &&
                allowsNotify
            )
                await fetchRecommendations();
        }
    };

    useEffect(() => {
        onMount();
    }, []);
};
