import { Box, BoxProps, Paper, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { MediaPlayer } from "JS/React/Components/MediaPlayer";
import { useMiniPlayer } from "JS/React/Hooks/MiniPlayer";
import { useEffect, useMemo, useState } from "react";
import { OnProgressProps } from "react-player/base";
import { useHistory } from "react-router-dom";
import { MiniPlayerOverlay } from "./MiniPlayerOverlay";
import { checkIfUrlIsBlob, getPublicUrl } from "JS/Helpers";
import { config } from "JS/Config";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { getAudioById } from "JS/Database/Audio";
import {
    Content,
    EventActions,
    EventCategories,
    EventNames,
    FirebaseEventAction,
    Gifts,
    OfflineBundle,
    VideosAndGifts,
    ContentWithMedia,
} from "JS/Models";
import { useFirebaseLogger } from "JS/React/Hooks/Firebase";
import {
    getCategoryForType,
    getEventNameForType,
} from "JS/Helpers/MiniPlayerHelper";
import { useGlobalPlaylistControls } from "JS/React/Hooks/PlaylistControls";
import { shuffle } from "lodash-es";
import {
    useResumeRecentlyAudio,
    useResumeRecentlyBundles,
    useResumeRecentlyOfflineContent,
    useResumeRecentlyPlaylist,
    useResumeRecentlyVideo,
} from "JS/React/Hooks/ResumeRecently";
import FallbackWaveform from "Images/Content/audio-waves.png";
import Draggable, {
    ControlPosition,
    DraggableEventHandler,
} from "react-draggable";
import { isIOS } from "react-device-detect";
import { useGiftDashboard } from "JS/React/Hooks/Firebase/GiftDashboard";
import {
    useReceivedMemberAudioGifts,
    useReceivedMemberVideoGifts,
} from "JS/React/Hooks/Gifts";
import {
    getPlayerPlayedTimeToLog,
    toSentGift,
    toSentGiftType,
} from "JS/Models/Firebase/GiftDashboard";
import { useGlobalAudioDetails, useGlobalAudios } from "JS/React/Hooks/Audio";

export const MiniMediaPlayer = (props: MiniMediaPlayerProps) => {
    const classes = useStyles(props)();

    const { dragPosition, handleOnDrag } = props;

    const {
        url,
        thumbnail,
        playerProgress,
        playerDuration,
        playing,
        title,
        isAudio,
        isLoop,
        playerRef,
        disableNavBtns,
        showNextPrevBtns,
        showButtons,
        isError,
        isReadyToPlay,
        setIsError,
        setPlaying,
        onClosePlayer,
        onMoveToParentScreen,
        handleProgress,
        onPlayerDuration,
        onTogglePlay,
        handleStart,
        handleReady,
        handleEnded,
        handleNext,
        handlePrevious,
        handlePlay,
        setIsReadyToPlay,
        playlist,
        bundle,
    } = useHandlers(props);

    const imageUrl = useMemo(() => {
        if (thumbnail && thumbnail !== "a") return thumbnail;

        return FallbackWaveform;
    }, [thumbnail]);

    useEffect(() => {
        // EXTREMELY CRITICAL CODE, NEVER TO BE REMOVED
        setTimeout(() => {
            if (isIOS && (!!playlist || !!bundle)) setIsReadyToPlay(true);
        }, 1500);
    }, []);

    return (
        <Draggable
            position={dragPosition}
            onDrag={handleOnDrag}
            bounds={"parent"}
            cancel="#expand, #closemax, #previous, #playpause, #next"
        >
            <Box className={clsx(classes.root, classes.maximize)}>
                <Paper
                    elevation={2}
                    variant={"elevation"}
                    className={classes.container}
                >
                    <MiniPlayerOverlay
                        isError={isError}
                        loading={!isReadyToPlay}
                        showButtons={showButtons}
                        playerProgress={playerProgress}
                        playerDuration={playerDuration}
                        playing={playing}
                        disableNavBtns={disableNavBtns}
                        title={title}
                        onClosePlayer={onClosePlayer}
                        onExpandClick={onMoveToParentScreen}
                        onPlayPauseClick={onTogglePlay}
                        onForwardClick={showNextPrevBtns ? handleNext : null}
                        onBackwardClick={
                            showNextPrevBtns ? handlePrevious : null
                        }
                    />

                    <MediaPlayer
                        isMini
                        title={title}
                        mediaURL={url}
                        imageURL={imageUrl}
                        playing={playing}
                        isAudio={isAudio}
                        loop={isLoop}
                        playerRef={playerRef}
                        onProgress={handleProgress}
                        onDuration={onPlayerDuration}
                        onStart={handleStart}
                        onEnded={handleEnded}
                        onReady={handleReady}
                        onError={(e) => setIsError(true)}
                        onPlay={handlePlay}
                        onPause={() => setPlaying(false)}
                    />
                </Paper>
            </Box>
        </Draggable>
    );
};

const useHandlers = (props: MiniMediaPlayerProps) => {
    const history = useHistory();
    const { playing, setPlaying } = props;

    const [disableNavBtns, setDisableNavBtns] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);

    const {
        miniPlayer,
        setGlobalMiniPlayer,
        resetGlobalMiniPlayer,
        resetGlobalMiniOnClose,
    } = useMiniPlayer();

    const { handlePlayAnalytics } = useFirebaseAnalytics();
    const { showButtons, onNextMedia, onPrevMedia, onComplete } =
        useHandlePlaylistBundles();

    const vidResume = useResumeRecentlyVideo(
        miniPlayer.content as VideosAndGifts,
        miniPlayer.content?.nid,
    );
    const audResume = useResumeRecentlyAudio(
        miniPlayer.content as Gifts | ContentWithMedia,
        miniPlayer.content?.nid,
    );
    const bundleResume = useResumeRecentlyBundles(
        miniPlayer.bundleAudio?.data as any,
        miniPlayer.bundleAudio?.mediaIndex,
        true,
    );
    const playlistResume = useResumeRecentlyPlaylist(
        miniPlayer.playlist?.data,
        miniPlayer.playlist?.playlistId,
        miniPlayer.playlist?.mediaIndex,
        miniPlayer.playlist?.bundleIndex,
        true,
    );
    const offlineContentResume = useResumeRecentlyOfflineContent(
        miniPlayer.offlineContent,
        miniPlayer.isAudio ? "audio" : "video",
    );

    const resumeFuncs: ResumeFuncs = useMemo(() => {
        if (props.isOffline) return offlineContentResume;
        if (miniPlayer.bundleAudio) {
            return bundleResume;
        }

        if (miniPlayer.playlist) {
            return playlistResume;
        }

        if (miniPlayer.content) {
            if (
                ["GiftMP3", "MP3", "GiftProsMP3"].includes(
                    miniPlayer.contentType,
                )
            )
                return audResume;
            if (
                ["GiftVideo", "Video", "GiftProsVideo"].includes(
                    miniPlayer.contentType,
                )
            )
                return vidResume;
        }

        return {
            handleRecentlyPlayed: () => {},
            handleContentCompleted: () => {},
            onMediaPlayerReady: () => {},
            onPlayerProgress: () => {},
            setIsReady: () => {},
            setIsReadyToPlay: () => {},
        } as any;
    }, [
        props.isOffline,
        miniPlayer,
        vidResume?.isReadyToPlay,
        bundleResume?.isReadyToPlay,
        audResume?.isReadyToPlay,
        playlistResume?.isReadyToPlay,
        offlineContentResume?.isReadyToPlay,
    ]);

    const {
        playerRef,
        handleRecentlyPlayed,
        handleContentCompleted,
        onMediaPlayerReady,
        onPlayerProgress,
        setIsReady,
        isReadyToPlay,
        setIsReadyToPlay,
    } = resumeFuncs;

    const { getAudioDetails } = useGlobalAudios();

    const getPlayerAction = () => {
        const media =
            "media" in miniPlayer?.content
                ? miniPlayer?.content?.media
                : getAudioDetails(miniPlayer?.content?.nid)?.media;
        const isBundle = media?.length > 1;
        const index = !!miniPlayer?.playlist
            ? miniPlayer.playlist.bundleIndex
            : miniPlayer?.bundleAudio?.mediaIndex;
        return getPlayerPlayedTimeToLog(
            playerRef,
            isBundle ? index + 1 : undefined,
        );
    };

    const { isGift: isAudioGift } = useReceivedMemberAudioGifts(true);
    const { isGift: isVideoGift } = useReceivedMemberVideoGifts(true);
    const isReceivedMemberGift =
        isAudioGift(miniPlayer?.content?.nid) ||
        isVideoGift(miniPlayer?.content?.nid);
    const { logGiftCompleted } = useGiftDashboard(
        toSentGift(
            miniPlayer?.content,
            miniPlayer?.bundleAudio?.mediaIndex ||
                miniPlayer?.playlist?.bundleIndex,
        ),
        toSentGiftType(miniPlayer?.contentType),
        "sender" in miniPlayer?.content
            ? miniPlayer?.content?.sender?.member_id
            : null,
        isReceivedMemberGift && playing,
        getPlayerAction,
    );

    const [playerProgress, setPlayerProgress] = useState<OnProgressProps>(null);
    const [playerDuration, setPlayerDuration] = useState<number>(null);

    const onClosePlayer = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        e.stopPropagation();
        setPlaying(false);
        resetGlobalMiniOnClose();
    };

    const onMoveToParentScreen = () => {
        const target = miniPlayer?.parentLink;
        if (target) {
            setPlaying(false);
            resetGlobalMiniPlayer(false);
            history.push(target);
        }
    };

    const onTogglePlay = () => {
        if (miniPlayer.forceStopPlaying)
            setGlobalMiniPlayer({
                forceStopPlaying: false,
            });

        setPlaying(!playing);
    };

    const handleProgress = (state: OnProgressProps) => {
        setPlayerProgress(state);
        if (state.playedSeconds > 5) {
            if (Math.floor(state.playedSeconds) % 2 === 0 && playing) {
                onPlayerProgress(state);
            }
        }
    };

    const onPlayerDuration = (duration: number) => {
        setPlayerDuration(duration);
    };

    const handleStart = () => {
        handlePlayAnalytics();
        handleRecentlyPlayed();
        onMediaPlayerReady();
    };

    const handleReady = () => {
        setDisableNavBtns(false);
        setIsError(false);
        setIsReadyToPlay(true);
    };

    const handleEnded = () => {
        handleContentCompleted();
        if (isReceivedMemberGift) {
            const action = getPlayerAction();
            logGiftCompleted(
                !!action.index ? { index: action.index } : undefined,
            );
        }
        setPlaying(onComplete());
    };

    const handleNext = () => {
        setDisableNavBtns(true);
        onNextMedia(false);
        setPlaying(true);
        setIsReady(false);
    };

    const handlePrevious = () => {
        setDisableNavBtns(true);
        onPrevMedia();
        setPlaying(true);
        setIsReady(false);
    };

    const handlePlay = () => {
        setPlaying(true);
        setGlobalMiniPlayer({
            forceStopPlaying: false,
        });
    };

    return {
        url: miniPlayer?.url,
        thumbnail: miniPlayer?.thumbnail,
        title: miniPlayer?.title,
        isAudio: miniPlayer?.isAudio,
        isLoop: miniPlayer?.singleLoop,
        bundle: miniPlayer?.bundleAudio,
        playlist: miniPlayer?.playlist,
        playerProgress,
        playerDuration,
        playing: playing && !miniPlayer.forceStopPlaying,
        playerRef,
        disableNavBtns,
        showNextPrevBtns: !!miniPlayer?.bundleAudio || !!miniPlayer?.playlist,
        showButtons,
        isError,
        isReadyToPlay,
        setIsError,
        onTogglePlay,
        onClosePlayer,
        onMoveToParentScreen,
        handleProgress,
        onPlayerDuration,
        handleStart,
        handleReady,
        handleEnded,
        handleNext,
        handlePrevious,
        setPlaying,
        handlePlay,
        setIsReadyToPlay,
    };
};

export const useHandlePlaylistBundles = () => {
    const { miniPlayer, setGlobalMiniPlayer } = useMiniPlayer();
    const { logFirebaseEvent } = useFirebaseLogger();

    const { verifyAwsKeys } = useVerifyAwsKeys();
    const { playlistControls } = useGlobalPlaylistControls();

    const { playlist, bundleAudio } = miniPlayer;

    const [playData, setPlayData] = useState<(Gifts | ContentWithMedia)[]>([]);
    const [showButtons, setShowButtons] = useState(true);
    const { audioDetails } = useGlobalAudioDetails(miniPlayer?.content?.nid);
    const media = audioDetails?.media;

    useEffect(() => {
        if (!playlist) return;

        const { data, mediaIndex, bundleIndex } = playlist;

        const currentAud = data[mediaIndex];

        if (playlistControls?.shuffle) {
            const shuffled = shuffle(
                data.filter((a) => a.nid !== currentAud?.nid),
            );
            setGlobalMiniPlayer({
                playlist: {
                    ...playlist,
                    mediaIndex: 0,
                    bundleIndex: bundleIndex,
                },
            });
            setPlayData([currentAud, ...shuffled]);
        } else {
            let current = undefined;
            let indexInPlaylist = undefined;
            if (playData) current = playData[mediaIndex];
            if (current) {
                indexInPlaylist = data?.indexOf(
                    data?.find((a) => a.nid === current.nid),
                );
            }

            setGlobalMiniPlayer({
                playlist: {
                    ...playlist,
                    mediaIndex: indexInPlaylist ? indexInPlaylist : mediaIndex,
                    bundleIndex: bundleIndex,
                },
            });
            setPlayData([...playlist.data]);
        }
    }, [playlist?.data, playlistControls?.shuffle]);

    const fetchThumbnail = async () => {
        if (playlist) {
            const { mediaIndex } = playlist;
            const data = playData;

            const audio = data[mediaIndex];

            return await getPublicUrl(
                audio.image_url_prefix,
                audio.image_url_postfix,
                audio.image_file_name,
            );
        }

        if (bundleAudio) {
            const { data, type } = bundleAudio;
            if (type === "online") {
                const audio = data as Gifts | Content;
                return await getPublicUrl(
                    audio.image_url_prefix,
                    audio.image_url_postfix,
                    audio.image_file_name,
                );
            }
        }
        return "";
    };

    const getBundleMediaUrl = async () => {
        const { data, mediaIndex, type } = bundleAudio;

        if (media) {
            const { media_title } = media[mediaIndex];

            if (type === "online") {
                setShowButtons(false);
                const aud = data as Gifts | Content;

                const contentNid = `${config.user.memberId}-${aud?.nid}-${
                    mediaIndex + 1
                }`;

                const isDwnlded = await checkAndSetIfDwnlded(
                    contentNid,
                    media_title,
                );

                const action: FirebaseEventAction = {
                    action: !isDwnlded
                        ? EventActions.LIVE_STREAM
                        : EventActions.PLAY,
                    nId: aud.nid,
                    contentTitle: media_title,
                    skuId: aud.sku_id,
                    category: getCategoryForType(miniPlayer.contentType),
                };

                logFirebaseEvent(
                    getEventNameForType(miniPlayer.contentType, isDwnlded),
                    action,
                );

                if (!isDwnlded) setIfNotDwnlded(media[mediaIndex]);
                else setShowButtons(true);
            }

            if (type === "offline") {
                const aud = data as OfflineBundle;
                const med = aud.media;

                const nid = aud?.id?.split("-")[1];

                const action: FirebaseEventAction = {
                    action: EventActions.PLAY,
                    nId: nid ? nid : "",
                    contentTitle: media_title,
                    skuId: aud.name,
                    category: EventCategories.AUDIOS,
                };

                logFirebaseEvent(EventNames.AUDIO_PLAYED, action);

                setGlobalMiniPlayer({
                    url: URL.createObjectURL(med[mediaIndex].blob),
                    title: med[mediaIndex].media_title,
                });
            }
        }
    };

    const getPlaylistMediaUrl = async () => {
        const { mediaIndex, bundleIndex } = playlist;
        const data = playData;

        const audio = data[mediaIndex];
        if (audio) {
            setShowButtons(false);
            const isBundle = media?.length > 1;
            const contentNid = isBundle
                ? `${audio.nid}-${bundleIndex + 1}`
                : audio.nid;

            const getTitle = () => {
                if (
                    !data ||
                    !Array.isArray(data) ||
                    mediaIndex < 0 ||
                    mediaIndex >= data.length ||
                    !data[mediaIndex]
                )
                    return "";
                const currentData: ContentWithMedia | Gifts = data[mediaIndex];
                return currentData?.media?.length > 1
                    ? currentData?.media[bundleIndex]?.media_title || ""
                    : currentData?.title || "";
            };

            const title = getTitle();

            const isDwnlded = await checkAndSetIfDwnlded(
                `${config.user.memberId}-${contentNid}`,
                title,
            );

            const action: FirebaseEventAction = {
                action: !isDwnlded
                    ? EventActions.LIVE_STREAM
                    : EventActions.PLAY,
                nId: audio.nid,
                category: getCategoryForType(miniPlayer.contentType),
                contentTitle: title,
                skuId: audio.sku_id,
            };

            logFirebaseEvent(
                getEventNameForType(miniPlayer.contentType, isDwnlded),
                action,
            );

            if (!isDwnlded) {
                const { media_url_prefix, media_url_postfix, media_file_name } =
                    "media" in audio ? media[bundleIndex] : audio;

                setIfNotDwnlded({
                    media_url_prefix: media_url_prefix,
                    media_url_postfix: media_url_postfix,
                    media_file_name: media_file_name,
                    media_title: title,
                });
            } else {
                setShowButtons(true);
            }
        }
    };

    const checkAndSetIfDwnlded = async (contentId: string, title: string) => {
        const dwnlded = await getAudioById(contentId);

        if (dwnlded) {
            setGlobalMiniPlayer({
                url: URL.createObjectURL(dwnlded.blob),
                title: title,
            });

            return true;
        }
    };

    const setIfNotDwnlded = async (options: {
        media_url_prefix: string;
        media_url_postfix: string;
        media_file_name: string;
        media_title: string;
    }) => {
        const {
            media_url_prefix,
            media_url_postfix,
            media_file_name,
            media_title,
        } = options;

        verifyAwsKeys().then(async () => {
            const thumb = await fetchThumbnail();
            getPublicUrl(
                media_url_prefix,
                media_url_postfix,
                media_file_name,
            ).then((signedURL) => {
                setShowButtons(true);
                setGlobalMiniPlayer({
                    url: signedURL,
                    title: media_title,
                    thumbnail: thumb,
                });
            });
        });
    };

    //internal helpers start from here
    const onNextPlaylist = (completed: boolean) => {
        if (!completed) {
            const { mediaIndex, bundleIndex } = miniPlayer.playlist;
            const { parentLink } = miniPlayer;
            const data = playData;

            const audio = data[mediaIndex];

            const noOfAudios = media?.length;
            const isBundle = noOfAudios > 1;

            const newIndex =
                isBundle && bundleIndex < noOfAudios - 1
                    ? mediaIndex
                    : (mediaIndex + 1) % data?.length;
            const newBundleIndex =
                isBundle && bundleIndex < noOfAudios - 1 ? bundleIndex + 1 : 0;
            const nextAudio = data[newIndex];

            setGlobalMiniPlayer({
                ...miniPlayer,
                parentLink: parentLink?.replace(
                    `index=${mediaIndex}&bundleIndex=${bundleIndex}`,
                    `index=${newIndex}&bundleIndex=${newBundleIndex}`,
                ),
                playlist: {
                    ...playlist,
                    mediaIndex: newIndex,
                    bundleIndex: newBundleIndex,
                },
                content: audio,
                contentType:
                    "content_item_type_name" in nextAudio
                        ? nextAudio?.content_item_type_name
                        : "MP3",
            });
        }
    };

    const onNextBundle = (completed: boolean) => {
        if (!completed) {
            const { mediaIndex } = miniPlayer.bundleAudio;
            const { parentLink } = miniPlayer;

            const noOfAudios = media?.length;
            const newMediaIndex = (mediaIndex + 1) % noOfAudios;

            setGlobalMiniPlayer({
                ...miniPlayer,
                parentLink: parentLink?.replace(
                    `index=${mediaIndex}`,
                    `index=${newMediaIndex}`,
                ),
                bundleAudio: {
                    ...miniPlayer.bundleAudio,
                    mediaIndex: newMediaIndex,
                },
            });
        }
    };

    const onPrevPlaylist = () => {
        const { mediaIndex, bundleIndex } = miniPlayer.playlist;
        const { parentLink } = miniPlayer;
        const data = playData;
        const noOfAudios = media?.length;
        const isBundle = noOfAudios > 1;

        let newMediaIndex = mediaIndex;
        if (mediaIndex === 0) {
            if (isBundle) {
                if (bundleIndex === 0) newMediaIndex = data?.length - 1;
                else newMediaIndex = mediaIndex;
            } else newMediaIndex = data?.length - 1;
        } else if (!isBundle || bundleIndex === 0)
            newMediaIndex = mediaIndex - 1;

        let newBundleIndex = bundleIndex;
        if (isBundle && bundleIndex !== 0) {
            newBundleIndex = bundleIndex - 1;
        } else if (newMediaIndex !== mediaIndex) {
            const previous = data[newMediaIndex];
            const isBundle = +previous.no_of_files > 1;
            newBundleIndex = isBundle ? +previous?.no_of_files - 1 : 0;
        }
        const previous = data[newMediaIndex];

        setGlobalMiniPlayer({
            ...miniPlayer,
            parentLink: parentLink?.replace(
                `index=${mediaIndex}&bundleIndex=${bundleIndex}`,
                `index=${newMediaIndex}&bundleIndex=${newBundleIndex}`,
            ),
            playlist: {
                ...playlist,
                mediaIndex: newMediaIndex,
                bundleIndex: newBundleIndex,
            },
            contentType:
                "content_item_type_name" in previous
                    ? previous?.content_item_type_name
                    : "MP3",
        });
    };

    const onPrevBundle = () => {
        const { mediaIndex } = miniPlayer.bundleAudio;
        const { parentLink } = miniPlayer;
        const newMediaIndex =
            mediaIndex === 0 ? media?.length - 1 : mediaIndex - 1;

        setGlobalMiniPlayer({
            ...miniPlayer,
            parentLink: parentLink?.replace(
                `index=${mediaIndex}`,
                `index=${newMediaIndex}`,
            ),
            bundleAudio: {
                ...miniPlayer?.bundleAudio,
                mediaIndex: newMediaIndex,
            },
        });
    };
    //internal helpers end here

    const onNextMedia = (completed: boolean) => {
        if (miniPlayer?.playlist) {
            onNextPlaylist(completed);
            return;
        }

        if (miniPlayer?.bundleAudio) {
            onNextBundle(completed);
            return;
        }
    };
    const onPrevMedia = () => {
        if (miniPlayer?.playlist) {
            onPrevPlaylist();
            return;
        }

        if (miniPlayer?.bundleAudio) {
            onPrevBundle();
            return;
        }
    };

    const onComplete = () => {
        let shouldPlayNext = false;
        if (miniPlayer?.playlist) {
            const { mediaIndex } = miniPlayer.playlist;
            const data = playData;
            const complete =
                playlistControls?.loop || mediaIndex === data?.length - 1;
            onNextMedia(complete);
            shouldPlayNext = !complete;
        } else if (miniPlayer?.bundleAudio) {
            const { mediaIndex } = miniPlayer?.bundleAudio;
            const complete = mediaIndex === media?.length - 1;
            onNextMedia(complete);
            shouldPlayNext = !complete;
        }
        return shouldPlayNext;
    };

    useEffect(() => {
        if (playlist && playData) {
            getPlaylistMediaUrl();
        }
    }, [playlist?.bundleIndex, playlist?.mediaIndex, playData]);

    useEffect(() => {
        if (bundleAudio) {
            getBundleMediaUrl();
        }
    }, [bundleAudio?.data, bundleAudio?.mediaIndex]);

    return {
        isPlaylistOrBundle: miniPlayer?.playlist || miniPlayer?.bundleAudio,
        showButtons,
        onNextMedia,
        onPrevMedia,
        onComplete,
    };
};

const useFirebaseAnalytics = () => {
    const { logFirebaseEvent } = useFirebaseLogger();
    const { miniPlayer } = useMiniPlayer();

    const isDownloaded = checkIfUrlIsBlob(miniPlayer.url);
    const recommendation = miniPlayer.recommendation;

    const handlePlayAnalytics = () => {
        if (miniPlayer?.content) {
            const { nid, sku_id, title } = miniPlayer.content;

            const action: FirebaseEventAction = {
                action: isDownloaded
                    ? EventActions.PLAY
                    : EventActions.LIVE_STREAM,
                nId: nid,
                contentTitle: title,
                skuId: sku_id,
                category: getCategoryForType(miniPlayer.contentType),
            };

            if (recommendation) {
                action.category = EventCategories.RECOMMENDATIONS;
                action.displayCount = recommendation.displayCount;
                action.source = recommendation.source;
                action.recId = `${recommendation.recId}`;
            }

            logFirebaseEvent(
                getEventNameForType(
                    miniPlayer.contentType,
                    isDownloaded,
                    !!recommendation,
                ),
                action,
            );
        }
    };

    return {
        handlePlayAnalytics,
    };
};

const useStyles = (props: MiniMediaPlayerProps) =>
    makeStyles((theme: Theme) =>
        createStyles({
            root: {
                position: "fixed",
                bottom: props.isOffline ? 0 : theme.footer.height,
                right: 0,
                zIndex: 1000,
                background: "grey",
                marginBottom: 5,
                marginRight: 5,
                borderRadius: "10px",
            },
            maximize: {
                height: "150px",
                width: "250px",
                [theme.breakpoints.up("sm")]: {
                    height: "210px",
                    width: "350px",
                },
                [theme.breakpoints.up("md")]: {
                    height: "250px",
                    width: "400px",
                },
                [theme.breakpoints.up("lg")]: {
                    height: "270px",
                    width: "450px",
                },
            },
            container: {
                position: "relative",
                height: "100%",
                backgroundColor: theme.palette.background.default,
            },
            miniXmark: {
                position: "absolute",
                top: 7,
                right: 7,
                fontSize: "0.8rem",
                cursor: "pointer",
                zIndex: 100,
            },
        }),
    );

export interface MiniMediaPlayerProps extends BoxProps {
    isOffline?: boolean;
    playing: boolean;
    dragPosition: ControlPosition;
    handleOnDrag: DraggableEventHandler;
    setPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

export type ResumeFuncs = {
    playerRef: React.MutableRefObject<any>;
    isReady: boolean;
    setIsReady: React.Dispatch<React.SetStateAction<boolean>>;
    onMediaPlayerReady: () => void;
    onPlayerProgress: (state: OnProgressProps) => Promise<any>;
    handleContentCompleted: () => void;
    handleRecentlyPlayed: () => void;
    isReadyToPlay?: boolean;
    setIsReadyToPlay?: React.Dispatch<React.SetStateAction<boolean>>;
};
