import { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@mui/styles";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Chip,
    GridProps,
    IconButton,
    InternalStandardProps,
    Theme,
    Typography,
} from "@mui/material";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FallbackWaveform from "Images/Content/FallbackWaveform.png";
import giftReceivedIcon from "Images/gifts_received.png";
import moment from "moment";
import { AppButton } from "./AppButton";
import { AppDownloadingIcons } from "./Icon/AppDownloadingIcons";
import { RenderIf } from "./Wrapper/RenderIf";

export type AppContentListItemType =
    | "media"
    | "courses"
    | "purchasableCourses"
    | "gift"
    | "receivedGift";

export interface AppContentListItemProps
    extends InternalStandardProps<GridProps> {
    isAllowClick?: boolean;
    onListItemClick?: () => void;
    onItemPlayClick?: () => void;
    imageUrl: string;
    type?: AppContentListItemType;
    contentPrice?: string;
    isRedeemableByToken?: string;
    numberOfTokens?: string;
    title: string;
    skuId: string;
    description: string;
    showPlayIcon?: boolean;
    showFavIcon?: boolean;
    isFavourite?: boolean;
    //Course Related Props
    isPurchased?: boolean;
    isResume?: boolean;
    creditsRequired?: number;
    publishFree?: boolean;
    isReceived?: boolean;
    isDownloadInProgress?: boolean;
    isInDownloadQueue?: boolean;
    isDownloaded?: Promise<boolean>;
    inProgressDownloaded?: boolean;
    giftChecked?: boolean;
    onGiftCheckChanged?: (nid: string, checked: boolean) => void;
    selectMode?: boolean;
    purchasedQuantity?: string;
    sortType?: string;
    releaseDate?: string;
    acquiredDate?: string;
    played?: string;
    sender?: {
        first_name?: string;
        last_name?: string;
        sending_date?: string;
    };
    attachedSku?: {
        prospect_sku_id?: string;
        member_sku_id?: string;
    };
    isGiftGiven?: boolean;
    disabled?: boolean;
    nid: string;
    style?: Object;
    isFromPlaylist?: boolean;
    handleToogleCheckBox?: (nid: string, value: boolean) => void;
    isCheckedItem?: boolean;
    isFromSpeakerPlaylist?: boolean;
}

export const AppContentListItem = (props: AppContentListItemProps) => {
    const classes = useStyles(props);
    const {
        isAllowClick,
        onListItemClick,
        imageUrl,
        title,
        skuId,
        description,
        showPlayIcon,
        showFavIcon,
        isFavourite,
        //Course Related Props
        publishFree,
        isPurchased,
        creditsRequired,
        isReceived,
        contentPrice,
        isRedeemableByToken,
        numberOfTokens,
        isDownloadInProgress,
        isInDownloadQueue,
        isDownloaded,
        inProgressDownloaded,
        type,
        giftChecked,
        onGiftCheckChanged,
        selectMode,
        purchasedQuantity,
        sender,
        sortType,
        releaseDate,
        acquiredDate,
        played,
        attachedSku,
        isResume,
        isGiftGiven = false,
        onItemPlayClick,
        disabled,
        nid,
        style,
        isFromPlaylist,
        handleToogleCheckBox,
        isCheckedItem,
        isFromSpeakerPlaylist,
    } = props;

    const [isMediaDownloaded, setIsMediaDownloaded] = useState<boolean>(false);
    const { handlePlayIconClick } = useHandlers(isAllowClick, onItemPlayClick);

    const toggleGiftChecked = () => onGiftCheckChanged(nid, !giftChecked);
    const showDate = () => {
        if (sortType) {
            if (sortType === "acquired_date") {
                if (acquiredDate && acquiredDate !== "0")
                    return `${formatDate(+acquiredDate)}`;
                else return "";
            }
            if (sortType === "played") {
                if (played && played !== "0")
                    return `${formatDateTime(+played)}`;
                else return "";
            } else if (releaseDate && releaseDate !== "0")
                return `${formatDate(+releaseDate)}`;
            else return "";
        } else if (releaseDate && releaseDate !== "0")
            return `${formatDate(+releaseDate)}`;
        else return "";
    };

    const giftingAvailable = () => {
        if (!attachedSku) return "";
        if (!!attachedSku.prospect_sku_id && !!attachedSku.member_sku_id)
            return "Gift Available (M, P)";
        else if (!!attachedSku.prospect_sku_id) return "Gift Available (P)";
        else if (!!attachedSku.member_sku_id) return "Gift Available (M)";
        return "";
    };

    const formatDate = (date: number) => {
        return moment.unix(date).format("ll");
    };
    const formatDateTime = (date: number) => {
        return `${moment.unix(date).format("ll")} @ ${moment
            .unix(date)
            .format("hh:mma")}`;
    };

    useEffect(() => {
        if (!!inProgressDownloaded) {
            setIsMediaDownloaded(true);
        }
        if (!!isDownloaded)
            isDownloaded
                ?.then((res) => {
                    setIsMediaDownloaded(res);
                })
                .catch(() => {
                    setIsMediaDownloaded(false);
                });
        else setIsMediaDownloaded(false);
    }, [isDownloaded, type, inProgressDownloaded, isDownloadInProgress]);

    const onCardClick = () => {
        if (selectMode) {
            toggleGiftChecked();
        } else {
            if (isAllowClick) {
                onListItemClick();
            }
        }
    };

    const isCourseType = () =>
        type === "courses" || type === "purchasableCourses";

    return (
        <Card
            onClick={() =>
                isFromSpeakerPlaylist
                    ? handleToogleCheckBox(nid, !isCheckedItem)
                    : onCardClick()
            }
            elevation={0}
            style={style}
            className={clsx(
                classes.root,
                !isFromPlaylist && classes.borderBottom,
                classes.flexVerticalCentered,
                classes.contentCard,
                disabled && classes.lowOpacity,
                isFromSpeakerPlaylist && classes.extraPadding,
            )}
        >
            <Box
                className={clsx(
                    classes.imageContainer,
                    isFromSpeakerPlaylist && classes.flexAlignment,
                )}
            >
                <img
                    src={imageUrl ? imageUrl : FallbackWaveform}
                    alt={"thumbnail"}
                    className={classes.thumbnail}
                />
                {!!purchasedQuantity && type === "gift" && (
                    <Typography className={clsx(classes.purchasedQuantity)}>
                        {purchasedQuantity}
                    </Typography>
                )}
            </Box>
            <CardContent
                className={clsx(
                    classes.cardContent,
                    type === "purchasableCourses" && classes.shortContent,
                )}
            >
                <Typography
                    className={clsx(
                        classes.title,
                        classes.grey600,
                        classes.singleLineDescription,
                    )}
                >
                    {title || ""}
                </Typography>
                <Typography className={classes.skuId}>{skuId || ""}</Typography>
                <Typography className={classes.skuId}>
                    {isGiftGiven ? `Sent Date: ${showDate()}` : showDate()}
                </Typography>
                <Typography className={classes.skuId}>
                    {giftingAvailable()}
                </Typography>
                <Typography
                    className={clsx(
                        type === "receivedGift" || type === "purchasableCourses"
                            ? classes.singleLineDescription
                            : classes.clampText,
                        classes.grey600,
                    )}
                    fontSize={"12px"}
                >
                    {description || ""}
                </Typography>
                {(((type === "courses" || type === "media") && isReceived) ||
                    type === "receivedGift") && (
                    <Box display="flex" className={classes.giftIcon}>
                        <Box
                            marginRight={"10px"}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <img
                                width={30}
                                height={30}
                                src={giftReceivedIcon}
                                alt={"gift-received-icon"}
                            />
                        </Box>
                        {sender && (
                            <Box>
                                {sender?.sending_date && (
                                    <Typography
                                        className={clsx(classes.boldGrey)}
                                    >
                                        {moment(sender?.sending_date).format(
                                            "LL",
                                        )}
                                    </Typography>
                                )}
                                <Typography className={clsx(classes.boldGrey)}>
                                    From: {sender?.first_name || ""}{" "}
                                    {sender?.last_name || ""}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </CardContent>

            <Box
                className={clsx(classes.actionButtons)}
                style={{ width: isCourseType() ? "auto" : "25px" }}
            >
                <RenderIf isTrue={type === "purchasableCourses"}>
                    {+contentPrice > 0 && (
                        <Chip
                            className={clsx(classes.chip)}
                            label={`$${parseFloat(contentPrice).toFixed(2)}`}
                            variant="outlined"
                        />
                    )}
                    {isRedeemableByToken === "1" && (
                        <Typography className={clsx(classes.numberOfTokens)}>
                            {numberOfTokens || ""} Credit
                            {+numberOfTokens > 1 ? "s" : ""}
                        </Typography>
                    )}
                </RenderIf>
                <RenderIf
                    isTrue={
                        type === "courses" &&
                        !publishFree &&
                        !isReceived &&
                        !isPurchased
                    }
                >
                    <AppButton
                        className={classes.btnContentPrice}
                        rounded
                        buttonVariant="grey-outlined"
                    >
                        {`$ ${parseFloat(contentPrice).toFixed(2)}`}
                    </AppButton>

                    {isRedeemableByToken === "1" && (
                        <AppButton
                            className={classes.btnContentPrice}
                            buttonVariant="grey-text"
                        >
                            {creditsRequired} Credit
                            {+creditsRequired > 1 && "s"}
                        </AppButton>
                    )}
                </RenderIf>
                <RenderIf isTrue={type === "media" || type === "receivedGift"}>
                    {showFavIcon && (
                        <FontAwesomeIcon
                            style={{
                                fontSize: "22px",
                                marginBottom: "15px",
                            }}
                            className={classes.grey600}
                            icon={[isFavourite ? "fas" : "far", "star"]}
                        />
                    )}
                    {showPlayIcon && (
                        <IconButton
                            className={classes.iconButton}
                            onClick={handlePlayIconClick}
                        >
                            <FontAwesomeIcon
                                style={{
                                    fontSize: "22px",
                                    marginBottom: "15px",
                                    visibility: showPlayIcon
                                        ? "visible"
                                        : "hidden",
                                }}
                                className={clsx(
                                    isResume
                                        ? classes.resumePlay
                                        : classes.grey600,
                                )}
                                icon={["fas", "play"]}
                            />
                        </IconButton>
                    )}
                </RenderIf>
                <>
                    {isDownloadInProgress && (
                        <AppDownloadingIcons iconType="in-progress" />
                    )}
                    {isInDownloadQueue && (
                        <AppDownloadingIcons iconType="queued" />
                    )}
                    {isMediaDownloaded && (
                        <FontAwesomeIcon
                            style={{
                                fontSize: "22px",
                            }}
                            className={classes.grey600}
                            icon={["far", "floppy-disk"]}
                        />
                    )}
                </>
                {(selectMode || isFromSpeakerPlaylist) && (
                    <Checkbox
                        onClick={(e) =>
                            isFromSpeakerPlaylist &&
                            handleToogleCheckBox(nid, !isCheckedItem)
                        }
                        checked={
                            isFromSpeakerPlaylist ? isCheckedItem : giftChecked
                        }
                        icon={
                            <RadioButtonUnchecked
                                className={clsx(classes.checkedIcon)}
                            />
                        }
                        checkedIcon={
                            <CheckCircleOutline
                                className={clsx(classes.checkedIcon)}
                            />
                        }
                    />
                )}
            </Box>
        </Card>
    );
};

const useHandlers = (isAllowClick: boolean, onItemPlayClick?: () => void) => {
    const handlePlayIconClick = (e) => {
        if (isAllowClick && onItemPlayClick) {
            e.stopPropagation();
            onItemPlayClick();
        }
    };
    return { handlePlayIconClick };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: `${theme.palette.background.default} !important`,
        },
        borderBottom: {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        },
        flexVerticalCentered: {
            display: "flex",
            alignItems: "flex-start",
            cursor: "pointer !important",
        },
        contentCard: {
            padding: "10px",
        },
        actionButtons: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            height: "100%",
            position: "relative",
            marginTop: "5px",
        },
        clampText: {
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        cardContent: {
            width: "60%",
            height: "100%",
            padding: "0 10px 0",
            position: "relative",
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
        },
        textContainer: {
            height: "auto",
            display: "flex",
            flexDirection: "column",
            paddingRight: "5px",
            width: "100%",
        },
        skuId: {
            fontSize: "14px",
            color: theme.palette.grey[500],
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        [theme.breakpoints.down("md")]: {
            cardContent: {
                width: "70%",
            },
        },
        [theme.breakpoints.down("sm")]: {
            cardContent: {
                width: "70%",
            },
        },
        shortContent: {
            width: "75%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        title: {
            fontSize: "18px",
            marginBottom: "5px",
        },
        grey600: {
            color: theme.palette.grey[600],
        },
        btnContentPrice: {
            height: "35px",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "8px",
            paddingRight: "8px",
            borderRadius: "16px",
        },
        chip: {
            width: "70px",
            color: theme.palette.grey[800],
            borderWidth: "2px",
            borderColor: theme.palette.grey[800],
        },
        numberOfTokens: {
            marginTop: "20px",
            color: theme.palette.grey[800],
            fontSize: "15px",
            textAlign: "center",
        },
        checkedIcon: {
            color: theme.palette.grey[700],
        },
        purchasedQuantity: {
            position: "absolute",
            width: "25px",
            height: "25px",
            borderRadius: "100px",
            backgroundColor: theme.palette.grey[900],
            fontSize: "12px",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: "5px",
            right: "14px",
        },
        imageContainer: {
            width: "80px",
            display: "flex",
            marginTop: "5px",
            justifyContent: "center",
            position: "relative",
        },
        extraPadding: {
            paddingTop: "10px",
            paddingBottom: "10px",
        },
        boldGrey: {
            fontSize: "12px",
            color: theme.palette.grey[600],
            fontWeight: "bold",
        },
        singleLineDescription: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        giftIconPositioner: {
            position: "absolute",
            bottom: 0,
            right: 10,
        },
        resumePlay: {
            color: theme.palette.primary.main,
        },
        iconButton: {
            padding: 0,
        },
        lowOpacity: {
            opacity: "50%",
        },
        flexAlignment: {
            justifyContent: "flex-start",
            width: "60px",
            flexShrink: 0,
        },
        thumbnail: {
            width: "60px",
            height: "auto",
        },
    }),
);
