import { appConstants } from "./Helpers/Contants";

export const config = {
    appVersion: "1.32.0",
    betaAppVersion: "1.32.0",
    accessToken: encryptAndDecryptXorString(
        localStorage.getItem("encryptedAccessToken") || "",
        process.env.REACT_APP_XOR,
    ),
    refreshToken: encryptAndDecryptXorString(
        localStorage.getItem("encryptedRefreshToken") || "",
        process.env.REACT_APP_XOR,
    ),
    baseApiUrl: process.env.REACT_APP_BASE_API_URL,
    feedbackBaseApiUrl: process.env.REACT_APP_FEEDBACK_BASE_API_URL,
    appEnvironment: process.env.REACT_APP_ENVIRONMENT,
    xTenantId: process.env.REACT_APP_X_TENANT_ID,
    s3: {
        xor: process.env.REACT_APP_XOR,
        accessKey: process.env.REACT_APP_AWS_ACCESS_KEY || "",
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY || "",
        region: process.env.REACT_APP_AWS_REGION,
        baseURL: process.env.REACT_APP_S3_BASE_URL,
        signedUrlExpiry: process.env.REACT_APP_SIGNED_URL_EXPIRY_TIME,
        events: {
            bucketName: process.env.REACT_APP_EVENTS_BUCKET,
            folder: process.env.REACT_APP_EVENTS_FOLDER,
            imagePath: process.env.REACT_APP_S3_IMAGE_PATH,
        },
        recommendations: {
            bucketName: process.env.REACT_APP_RECOMMENDATIONS_BUCKET,
            key: process.env.REACT_APP_RECOMMENDATIONS_KEY,
        },
        mediaEssentials: {
            bucketName: process.env.REACT_APP_MEDIA_ESSENTIALS_BUCKET,
            key: process.env.REACT_APP_MEDIA_ESSENTIALS_KEY,
        },
        landingPage: {
            bucketName: process.env.REACT_APP_LANDING_PAGE_BUCKET,
        },
        media: {
            bucketName: process.env.REACT_APP_BETA_USER_BUCKET,
            betaUserFilePath: process.env.REACT_APP_BETA_USER_JSON_PATH,
        },
        documents: {
            baseUrl: process.env.REACT_APP_S3_DOCUMENTS_BASE_URL,
            description: process.env.REACT_APP_S3_DOCUMENTS_DESCRIPTION,
        },
    },
    user: {
        memberId: localStorage.getItem("memberId") || "",
        isPlayedMigrated: localStorage.getItem("playedMigrated") === "true",
        isResumeMigrated: localStorage.getItem("resumeMigrated") === "true",
        firstName: localStorage.getItem(appConstants.localStorage.firstName),
        lastName: localStorage.getItem(appConstants.localStorage.lastName),
    },
    firebase: {
        courses: {
            token: encryptAndDecryptXorString(
                localStorage.getItem("encryptedCourseFirebaseToken") || "",
                process.env.REACT_APP_XOR,
            ),
            apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FIREBASE_APP_ID,
            measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
        },
        webcast: {
            token: encryptAndDecryptXorString(
                localStorage.getItem("encryptedWebcastsFirebaseToken") || "",
                process.env.REACT_APP_XOR,
            ),
            apiKey: process.env.REACT_APP_WEBCAST_API_KEY,
            authDomain: process.env.REACT_APP_WEBCAST_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_WEBCAST_DATABASE_URL,
            projectId: process.env.REACT_APP_WEBCAST_PROJECT_ID,
            storageBucket: process.env.REACT_APP_WEBCAST_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_WEBCAST_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_WEBCAST_APP_ID,
        },
        logging: {
            apiKey: process.env.REACT_APP_LOGGING_FIREBASE_API_KEY,
            authDomain: process.env.REACT_APP_LOGGING_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_LOGGING_FIREBASE_DATABASE_URL,
            projectId: process.env.REACT_APP_LOGGING_PROJECT_ID,
            storageBucket:
                process.env.REACT_APP_LOGGING_FIREBASE_STORAGE_BUCKET,
            messagingSenderId:
                process.env.REACT_APP_LOGGING_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_LOGGING_FIREBASE_APP_ID,
        },
        fcm: {
            apiKey: process.env.REACT_APP_FCM_API_KEY,
            authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
            databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
            projectId: process.env.REACT_APP_FCM_PROJECT_ID,
            storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_FCM_APP_ID,
            measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
            vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
            appName: "PushNotifications",
        },
    },
    sentry: {
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        traceSampleRate: 1.0,
    },
    storageQuota: {
        //in GB
        lowerLimit: 2,
        upperLimit: 3,
    },
    downloading: {
        maxDownloadingSize: 250,
    },
    blockSeamless: {
        start: +process.env.REACT_APP_BLOCK_LAUNCH_SEAMLESS_START,
        end: +process.env.REACT_APP_BLOCK_LAUNCH_SEAMLESS_END,
    },
    consentForm: {
        fileName: process.env.REACT_APP_CONSENT_FORM_FILE,
    },
};

export function encryptAndDecryptXorString(encVal: string, key: string) {
    if (encVal?.length && key?.length) {
        const arrEncVal = encVal.split("");
        const arrKey = key.split("");
        const strLen = arrEncVal.length;
        const keyLen = arrKey.length;
        const StringFromCharCode = String.fromCharCode;

        for (let i = 0; i < strLen; i++) {
            arrEncVal[i] = StringFromCharCode(
                arrEncVal[i].charCodeAt(0) ^ arrKey[i % keyLen].charCodeAt(0),
            );
        }

        return arrEncVal.join("");
    }
    return "";
}
