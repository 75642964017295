import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    Dialog,
    DialogProps,
    InternalStandardProps,
    Slide,
    Theme,
    Grid,
    IconButton,
    Box,
    DialogContent,
    ListItem,
    ListItemButton,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { createStyles, makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { Content, Gifts } from "JS/Models";
import { AppTypography } from "JS/React/Components/AppTypography";
import { useVerifyAwsKeys } from "JS/React/Hooks/Media";
import { getPublicUrl } from "JS/Helpers";
import { messaging } from "JS/Helpers/UserMessaging";
import { AppStandardTextFieldWithRef } from "JS/React/Components/TextFields/AppStandardTextField";
import { AppCircularProgress } from "JS/React/Components/Progress/AppCircularProgress";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListOnItemsRenderedProps } from "react-window";
import { SpeakerPlaylistRowItem } from "./SpeakerPlaylistRowItem";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SpeakerPlaylistDialog = (props: SpeakerPlaylistDialogProps) => {
    const {
        audios,
        handleClose,
        handleCreatePlaylist,
        onToggleSelectItem,
        selectedContentNids,
        sortType,
        playlistName,
        setPlaylistName,
        createPlaylistLoading = false,
        open,
        ...rest
    } = props;
    const classes = useStyles(props);
    const { className } = props;

    const inputRef = useRef(null);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            const inputElement = document.activeElement as HTMLInputElement;
            if (inputElement) {
                inputElement.blur();
            }
        }
    };

    const { visibleIndices, onRender } = useItemsRendered();
    const { finalData, thumbnails } = useSpeakerPlaylist(
        audios,
        visibleIndices,
    );

    return (
        <Dialog
            fullWidth
            role="dialog"
            className={classes.root}
            open={open}
            TransitionComponent={Transition}
            {...rest}
        >
            <Box className={clsx(className, classes.dialogWarpper)}>
                <DialogContent className={classes.mainContent}>
                    <Grid container className={classes.topContent}>
                        <Grid
                            item
                            xs={12}
                            className={classes.contentHeaderItem}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={"flex-start"}
                                alignItems={"center"}
                            >
                                <AppTypography
                                    variant="h6"
                                    aria-label={"Create Playlist"}
                                >
                                    Create Playlist
                                </AppTypography>
                            </Box>
                            <IconButton onClick={handleClose}>
                                <FontAwesomeIcon
                                    className={classes.filterIcon}
                                    icon={["fas", "xmark"]}
                                />
                            </IconButton>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{ marginTop: "5vh", marginBottom: "2%" }}
                        >
                            <AppStandardTextFieldWithRef
                                ref={inputRef}
                                fullWidth
                                placeholder="Playlist Name"
                                value={playlistName}
                                onChange={(e) =>
                                    setPlaylistName(e.target.value)
                                }
                                onKeyDown={handleKeyPress}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            className={classes.warpperChkBoxList}
                        >
                            {finalData?.length ? (
                                <Box className={classes.listHeight}>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <FixedSizeList
                                                height={height}
                                                itemCount={
                                                    finalData?.length || 0
                                                }
                                                itemSize={140}
                                                width={width}
                                                onItemsRendered={onRender}
                                            >
                                                {({ index, style }) => (
                                                    <>
                                                        <SpeakerPlaylistRowItem
                                                            index={index}
                                                            style={style}
                                                            listItem={
                                                                finalData[index]
                                                            }
                                                            key={`${finalData[index].nid}-${index}`}
                                                            imageUrl={
                                                                thumbnails &&
                                                                thumbnails[
                                                                    finalData[
                                                                        index
                                                                    ].nid
                                                                ]
                                                            }
                                                            handleToogleCheckBox={
                                                                onToggleSelectItem
                                                            }
                                                            isCheckedItem={selectedContentNids?.includes(
                                                                finalData[index]
                                                                    .nid,
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </FixedSizeList>
                                        )}
                                    </AutoSizer>
                                </Box>
                            ) : (
                                <AppTypography
                                    className={classes.emptyList}
                                    align="center"
                                    variant="h6"
                                >
                                    {messaging.common.noItemsFound}
                                </AppTypography>
                            )}
                        </Grid>
                    </Grid>
                    {selectedContentNids?.length > 0 && (
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={classes.btnCreateWrapper}
                            >
                                <ListItem key="btnOk" disablePadding>
                                    <ListItemButton
                                        className={classes.btnCreate}
                                        onClick={
                                            !createPlaylistLoading
                                                ? handleCreatePlaylist
                                                : () => {}
                                        }
                                    >
                                        {createPlaylistLoading ? (
                                            <AppCircularProgress
                                                notPrimary={true}
                                                size={"small"}
                                            />
                                        ) : (
                                            "Create"
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
            </Box>
        </Dialog>
    );
};

const useSpeakerPlaylist = (
    audios: (Content | Gifts)[],
    visibleIndices: { start: number; end: number },
) => {
    const { verifyAwsKeys } = useVerifyAwsKeys();

    const visibleData = useMemo(() => {
        if (!audios || !visibleIndices) return [];
        return audios.slice(visibleIndices.start, visibleIndices.end);
    }, [audios, visibleIndices]);

    const [thumbnails, setThumbnails] = useState<{
        [key: string]: string;
    }>(null);

    useEffect(() => {
        if (audios?.length) {
            verifyAwsKeys().then(() => {
                fetchThumbnails();
            });
        }
    }, [visibleData]);

    const createImageUrl = async (audio: Content | Gifts) => {
        const url = await getPublicUrl(
            audio.image_url_prefix,
            audio.image_url_postfix,
            audio.image_file_name,
        );

        return {
            id: audio.nid,
            url: url,
        };
    };

    const fetchThumbnails = async () => {
        let promises: Promise<{
            id: string;
            url: string;
        }>[] = [];

        visibleData?.forEach((d) => {
            if (!thumbnails || (thumbnails && !thumbnails[d.nid])) {
                const promise = createImageUrl(d as Content);
                promises.push(promise);
            }
        });

        const thumbs = await Promise.all(promises);

        let toRet: {
            [key: string]: string;
        } = {};

        thumbs.forEach((t) => {
            toRet = {
                ...toRet,
                [t.id]: t.url,
            };
        });

        setThumbnails((prev) => ({
            ...prev,
            ...toRet,
        }));
    };

    return {
        finalData: audios,
        thumbnails,
    };
};

const useItemsRendered = () => {
    const [visibleIndices, setVisibleIndices] = useState<{
        start: number;
        end: number;
    }>();

    const onRender = (props: ListOnItemsRenderedProps) => {
        setVisibleIndices({
            start: props.visibleStartIndex,
            end: props.visibleStopIndex + 1,
        });
    };

    return {
        visibleIndices,
        onRender,
    };
};

export interface SpeakerPlaylistDialogProps
    extends InternalStandardProps<DialogProps> {
    audios: (Content | Gifts)[];
    handleClose: () => void;
    handleCreatePlaylist: () => void;
    onToggleSelectItem: (nid: string, checked: boolean) => void;
    selectedContentNids: string[];
    sortType?: string;
    setPlaylistName: React.Dispatch<React.SetStateAction<string>>;
    playlistName: string;
    createPlaylistLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            margin: theme.spacing(1),
        },
        button: {
            width: "100%",
        },
        filterDialogTitle: {
            color: theme.palette.common.black,
        },
        filterIcon: {
            fontSize: "22px",
            cursor: "pointer",
            color: theme.palette.grey[600],
        },
        root: {
            boxShadow: `0px -8px 10px ${theme.palette.grey[200]}`,
            minWidth: "100%",
            minHeight: `calc(95vh - (${theme.footer.height} + ${theme.header.height}))`,
            border: `1px solid ${theme.palette.grey[200]}`,
        },
        dialogWarpper: {
            backgroundColor: `${theme.palette.background.default}`,
            color: `${theme.palette.common.black}`,
        },
        warpperChkBoxList: {
            overflow: "auto",
            height: `calc(70vh - (${theme.footer.height} + ${theme.header.height}))`,
            backgroundColor: theme.palette.background.default,
        },
        mainContent: {
            padding: 0,
        },
        topContent: {
            padding: theme.spacing(2),
        },

        contentHeaderItem: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        btnCreateWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        btnCreate: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.colors.white,
            },
        },
        navLoadMore: {
            backgroundColor: theme.palette.primary.main,
            color: theme.colors.white,
            height: `calc(${theme.footer.height} - 40px)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        emptyList: {
            marginTop: theme.spacing(3),
            color: theme.palette.grey[500],
        },
        listHeight: {
            height: "100%",
        },
    }),
);
