import { Content, ContentWithMedia, Gifts, Playlist } from "JS/Models";
import {
    useGlobalGifts,
    useReceivedMemberAudioGifts,
    useReceivedProspectAudioGifts,
} from "JS/React/Hooks/Gifts";
import { useEffect, useMemo, useState } from "react";
import { getDownloadedAudios, shuffle } from "JS/Helpers";
import { useGlobalAudios } from "JS/React/Hooks/Audio";

export const usePlaybackAudios = (
    playlist: Playlist,
    allAudios: Content[],
    isShuffle: boolean,
    mediaIndex: number,
    bundleIndex: number,
    setQueryParams: (
        params: Partial<{
            index: string;
            bundleIndex: string;
            scrollTo: string | number | string[] | qs.ParsedQs | qs.ParsedQs[];
        }>,
    ) => void,
    isOffline: boolean,
) => {
    const { receivedMemberAudioGifts, receivedProspectAudioGifts } =
        useGlobalGifts();
    const { loading: rmgLoading, isGift } = useReceivedMemberAudioGifts(
        !isOffline ||
            (receivedMemberAudioGifts && receivedMemberAudioGifts.length > 0),
    );
    const { loading: rmpLoading } = useReceivedProspectAudioGifts(
        !isOffline || !!receivedProspectAudioGifts?.length,
    );
    const [downloadedAudios, setDownloadedAudios] = useState<
        (ContentWithMedia | Gifts)[]
    >([]);
    const { details, getAudioDetails } = useGlobalAudios();

    const sortedMappedAudios: (Gifts | ContentWithMedia)[] = useMemo(() => {
        return [...playlist?.audios]
            ?.sort((a, b) => +a.sort_order - +b.sort_order)
            ?.map((a) => {
                const selfAudio = allAudios?.find((aa) => aa?.nid === a?.nid);
                if (selfAudio)
                    return {
                        ...selfAudio,
                        ...getAudioDetails(a.nid),
                    };
                const memberGiftAudio = receivedMemberAudioGifts?.find(
                    (rmg) => rmg?.nid === a?.nid,
                );
                if (memberGiftAudio) return memberGiftAudio;
                return receivedProspectAudioGifts?.find(
                    (rgp) => rgp?.nid === a?.nid,
                );
            })
            ?.filter((a) => a !== undefined && Object.keys(a).length > 0);
    }, [
        playlist,
        receivedMemberAudioGifts?.length,
        receivedProspectAudioGifts?.length,
        allAudios?.length,
        isOffline,
        details,
    ]);

    const rMGNids = useMemo(() => {
        return receivedMemberAudioGifts
            ?.filter((a) =>
                playlist?.audios?.map((pa) => pa?.nid).includes(a?.nid),
            )
            .map((a) => a?.nid);
    }, [playlist, receivedMemberAudioGifts]);

    const rMPNids = useMemo(() => {
        return receivedProspectAudioGifts
            ?.filter((a) =>
                playlist?.audios?.map((pa) => pa?.nid).includes(a?.nid),
            )
            .map((a) => a?.nid);
    }, [playlist, receivedProspectAudioGifts]);

    const allAudioNids = useMemo(() => {
        return allAudios
            ?.filter((a) =>
                playlist?.audios?.map((pa) => pa?.nid).includes(a?.nid),
            )
            .map((a) => a?.nid);
    }, [playlist, allAudios]);

    const playlistAudios: (Gifts | ContentWithMedia)[] = useMemo(() => {
        if (!!sortedMappedAudios?.length) {
            let playlist: (Gifts | ContentWithMedia)[] = [];
            if (isShuffle) {
                const current = sortedMappedAudios[mediaIndex];
                const shuffled = shuffle(
                    sortedMappedAudios.filter((a) => a.nid !== current?.nid),
                );
                playlist = [current, ...shuffled];
                setQueryParams({
                    index: "0",
                    bundleIndex: bundleIndex.toString(),
                });
            } else playlist = sortedMappedAudios;
            if (isOffline)
                return playlist
                    .map((a) => downloadedAudios.find((r) => r.nid === a.nid))
                    .filter((a) => a !== undefined);
            return playlist;
        }
    }, [
        sortedMappedAudios,
        allAudioNids.length,
        rMGNids.length,
        rMPNids.length,
        isShuffle,
        downloadedAudios,
        isOffline,
    ]);

    const getAudioWithMedia = (index: number) =>
        playlistAudios?.length && playlistAudios[index];

    const currentAudio = useMemo(
        () => getAudioWithMedia(mediaIndex),
        [playlistAudios, mediaIndex],
    );

    useEffect(() => {
        const c = async () => {
            const downloaded = await getDownloadedAudios(sortedMappedAudios);
            setDownloadedAudios(downloaded);
        };
        c();
    }, [sortedMappedAudios]);

    const title = useMemo(() => {
        if (!currentAudio) return "";
        return currentAudio?.media?.length > 1
            ? currentAudio.media[bundleIndex]?.media_title
            : currentAudio.title;
    }, [currentAudio, bundleIndex]);

    return {
        playlistAudios,
        rmgLoading,
        rmpLoading,
        isGift,
        currentAudio,
        getAudioByIndex: getAudioWithMedia,
        sortedMappedAudios,
        title,
    };
};
