import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { config, encryptAndDecryptXorString } from "JS/Config";
import { isDeviceAllowed } from "JS/Helpers";
import { useAuth } from "../Context/AuthenticationProvider";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRouting } from "./Routes";
import { useAppSelector } from "JS/Redux/Store";
import {
    initialState,
    setGlobalMediaEssentials,
} from "JS/Redux/MediaEssentials";
import { useVerifyAwsKeys } from "./Media";
import { MediaEssentialsService } from "JS/Services/MediaEssentials";
import { useGlobalAttributes } from "./Attributes";
import { MediaEssentials } from "JS/Models/MediaEssentials";

const service = new MediaEssentialsService();

const ESSENTIAL_INTERVAL_TIME = 30;

export const useMediaEssentials = (skip: boolean = true) => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState<MediaEssentials>();
    const { setGlobalMediaEssentialsState } = useGlobalMediaEssentials();
    const { resetAllAttributes } = useGlobalAttributes();

    const refetch = useCallback(() => {
        setLoading(true);
        return service
            .getS3MediaEssentials()
            .then(async (res) => {
                if (
                    !res?.allow_seamless_update_react &&
                    res?.access_key_react?.length &&
                    res?.secret_key_react?.length
                ) {
                    config.s3.accessKey = encryptAndDecryptXorString(
                        res?.access_key_react,
                        config.s3.xor,
                    );
                    config.s3.secretAccessKey = encryptAndDecryptXorString(
                        res?.secret_key_react,
                        config.s3.xor,
                    );
                }
                const resultKeys = Object.keys(res);
                const payload: MediaEssentials = {
                    ...res,
                    allow_rule_engine_requests: resultKeys.includes(
                        "allow_rule_engine_requests",
                    )
                        ? res?.allow_rule_engine_requests
                        : initialState.allow_rule_engine_requests,
                    allow_seamless_update_react: resultKeys.includes(
                        "allow_seamless_update_react",
                    )
                        ? res?.allow_seamless_update_react
                        : initialState.allow_seamless_update_react,
                    has_event_started: resultKeys.includes("has_event_started")
                        ? res?.has_event_started
                        : initialState.has_event_started,
                    download_warning_count: resultKeys.includes(
                        "download_warning_count",
                    )
                        ? res?.download_warning_count
                        : initialState.download_warning_count,
                    download_warning_msg: resultKeys.includes(
                        "download_warning_msg",
                    )
                        ? res?.download_warning_msg
                        : initialState.download_warning_msg,
                    download_warning_cleared_on_logout: resultKeys.includes(
                        "download_warning_cleared_on_logout",
                    )
                        ? res?.download_warning_cleared_on_logout
                        : initialState.download_warning_cleared_on_logout,
                    allow_fetch_team_status: resultKeys.includes(
                        "allow_fetch_team_status",
                    )
                        ? res?.allow_fetch_team_status
                        : initialState.allow_fetch_team_status,
                    allow_fyc_status_request: resultKeys.includes(
                        "allow_fyc_status_request",
                    )
                        ? res?.allow_fyc_status_request
                        : initialState.allow_fyc_status_request,
                    allow_launch_seamless_update_react:
                        res?.allow_launch_seamless_update_react,
                    allow_speaker_feature: resultKeys.includes(
                        "allow_speaker_feature",
                    )
                        ? res?.allow_speaker_feature
                        : initialState.allow_speaker_feature,
                    allow_consent_form_feature: resultKeys.includes(
                        "allow_consent_form_feature",
                    )
                        ? res?.allow_consent_form_feature
                        : initialState.allow_consent_form_feature,
                    access_key_react: res?.access_key_react,
                    secret_key_react: res?.secret_key_react,
                    player_progress_interval_courses: resultKeys.includes(
                        "player_progress_interval_courses",
                    )
                        ? res?.player_progress_interval_courses
                        : initialState.player_progress_interval_courses,
                };
                setGlobalMediaEssentialsState(payload);
                setResponse(payload);
                if (!payload?.allow_speaker_feature) {
                    resetAllAttributes();
                }
                if (
                    !!res.react_app_version &&
                    config.appVersion !== res.react_app_version
                )
                    window.location.reload();
                return payload;
            })
            .catch((err) => {
                return null as MediaEssentials;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (!skip) {
            refetch();
        }
    }, [skip]);

    return {
        refetch,
        response,
        loading,
    };
};

export const useEssentialsInterval = () => {
    const { isAuthenticated } = useAuth();
    const { refetch: fetchMediaEssentials } = useMediaEssentials();
    const { mediaEssentials } = useGlobalMediaEssentials();

    useEffect(() => {
        let interval = null;
        if (isAuthenticated) {
            if (mediaEssentials?.has_event_started) {
                interval = setInterval(async () => {
                    await fetchMediaEssentials();
                }, ESSENTIAL_INTERVAL_TIME * 60000);
            } else {
                clearInterval(interval);
            }
        }
        return () => clearInterval(interval);
    }, [isAuthenticated, mediaEssentials]);
};

export const useRedirectToWebCast = () => {
    const { isAuthenticated, redirectedToWebcast, setRedirectedToWebcast } =
        useAuth();
    const { mediaEssentials } = useGlobalMediaEssentials();
    const history = useHistory();
    const { linkProvider } = useRouting();

    const redirectToWebcast = useCallback(() => {
        if (isAuthenticated && mediaEssentials?.has_event_started) {
            history.push(linkProvider.react.webcast().index(true));
        }
    }, [isAuthenticated, mediaEssentials]);

    useEffect(() => {
        if (!redirectedToWebcast) {
            redirectToWebcast();
            setRedirectedToWebcast(true);
        }
    }, [redirectedToWebcast]);

    return {
        redirectToWebcast,
    };
};

export const useGlobalMediaEssentials = () => {
    const dispatch = useDispatch();

    const mediaEssentials = useAppSelector((state) => state.mediaEssentials);

    return {
        mediaEssentials,
        setGlobalMediaEssentialsState: (payload: MediaEssentials) => {
            dispatch(setGlobalMediaEssentials(payload));
        },
    };
};
